import { Input } from "reactstrap";
import { TestQuesOpenTextType } from "../../../models/TestTypes";
const TextBox = (data: TestQuesOpenTextType): JSX.Element => {
  return (
    <div className="custom-control  mb-3">
      <Input
        id="msoInput"
        name="msoInput"
        value={data.answer}
        onBlur={data.inputHandler}
        onChange={data.inputOnchange}
        rows="5"
        type="textarea"
      />
    </div>
  );
};
export default TextBox;
