export function MSODashboardReducer(state: any, action: any) {
  switch (action.type) {
    case "setLoader": {
      return {
        ...state,
        error: "",
        loader: action.payload,
      };
    }

    case "setList": {
      return {
        ...state,
        error: "",
        list: action.payload,
      };
    }

    default:
      return state;
  }
}

export const initialState = {
  loader: true,
  list: "",
};
