import { useReducer } from "react";
import { useHistory } from "react-router";
import Loading from "../../components/Loading";
import "./style.scss";
import MobileForm from "./MobileForm";
import OTPForm from "./OTPForm";
import { GetOtpGql, ValidateOtpGql } from "../../graphql/login";
import { useMutation } from "@apollo/client";
import { Card, CardBody, Container, Row, Col } from "reactstrap";
import { loginReducer, initialState } from "../../reducer/loginReducer";
import SingUpForm from "./SingUpForm";
import { ToastContainer, toast } from "react-toastify";
const SingUp = () => {
  const [state, dispatch] = useReducer(loginReducer, initialState);

  const { push } = useHistory();
  const [getOtp] = useMutation(GetOtpGql);
  const [CheckOtp] = useMutation(ValidateOtpGql);

  const clearOtp = (e: React.MouseEvent<HTMLButtonElement>) => {
    dispatch({ type: "clearOTP" });
  };
  const SingInHandler = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    push("/login");
  };
  const OTPValidate = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    dispatch({ type: "setLoader", payload: true });
    const res = await CheckOtp({
      variables: { mobile: state.mobileNo, otp: parseInt(state.OTP) },
    });
    if (res.data.ValidateOtp.code === 200) {
      dispatch({ type: "setLoader", payload: false });
      dispatch({ type: "setViewSingupForm", payload: true });
    } else {
      dispatch({ type: "otperror" });
      toast.error(res.data.ValidateOtp.message);
    }
  };

  const validateMobile = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch({ type: "setLoader", payload: true });
    const res = await getOtp({
      variables: { mobile: state.mobileNo, ReqType: "SingUp" },
    });

    if (res.data.GetOTP.code === 200) {
      toast.success("OTP is sent on " + state.mobileNo);
      dispatch({ type: "ValidMobile" });
      dispatch({ type: "setTimer", payload: true });
    } else {
      dispatch({ type: "mobileerror" });
      toast.error(res.data.GetOTP.message);
    }
    return false;
  };

  return (
    <div style={{ background: "#33424e", minHeight: "100vh" }}>
      <Container className="pt-5 pb-5" >
        <Row className="justify-content-center">
          <Col lg="6" md="12">
            <Card className="bg-secondary border-0 mb-0" style={{minHeight:"50vh", alignItems: "center"}}>
              <CardBody className="px-lg-12 py-lg-12">
                <div className="text-center text-muted mb-2">
                  <h4 className="texth3">MSO Training Sign-up Form</h4>
                  {state.viewOtpForm === false ? (
                    <MobileForm
                      value={state.mobileNo}
                      onClick={validateMobile}
                      onChange={(e: React.FormEvent<HTMLInputElement>) =>
                        dispatch({
                          type: "setInteger",
                          fieldName: "mobileNo",
                          length: 10,
                          payload: e.currentTarget.value,
                        })
                      }
                    />
                  ) : state.viewSingupForm === false ? (
                    <OTPForm
                      mobileNo={state.mobileNo}
                      OTP={state.OTP}
                      onChange={(e: React.FormEvent<HTMLInputElement>) =>
                        dispatch({
                          type: "setInteger",
                          fieldName: "OTP",
                          length: 6,
                          payload: e.currentTarget.value,
                        })
                      }
                      clearOtp={clearOtp}
                      OTPValidate={OTPValidate}
                      resendOTP={validateMobile}
                      watch={state.timer}
                    />
                  ) : (
                    <SingUpForm mobile={state.mobileNo} OTP={state.OTP} />
                  )}
                </div>
                <div>
                  <div style={{ width: "100%" }} className="text-center">
                    <a
                      href="/#"
                      title="New registration"
                      className="singupLink texth3"
                      onClick={(e: React.MouseEvent<HTMLElement>) =>
                        SingInHandler(e)
                      }
                    >
                      Sign-in
                    </a>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {state.loader ? <Loading /> : ""}
      <ToastContainer />
    </div>
  );
};

export default SingUp;
