import { useContext } from "react";
import { Card, CardHeader, CardBody, Container, Row, Col } from "reactstrap";
import { GetMSODashboardGql } from "../../graphql/dashboard";
import { useLazyQuery } from "@apollo/client";
import Loading from "../../components/Loading";
import CardsHeader from "../../components/Header/CardsHeader";
import { useEffect, useReducer } from "react";
import ModuleCard from "./DashBoardCard";
import { LoginContext } from "../../context/loginContext";
import {
  MSODashboardReducer,
  initialState,
} from "../../reducer/dashboardReducer";

const Dashboard = (): JSX.Element => {
  const [state, dispatch] = useReducer(MSODashboardReducer, initialState);
  const ctx = useContext(LoginContext);
  const [loadDashboard] = useLazyQuery(GetMSODashboardGql);

  useEffect(() => {
    async function fetchMyAPI() {
      const res = await loadDashboard({
        variables: {
          orderBy: [{ seqno: "asc" }],
          uid: ctx.userId,
        },
      });
      if (res.data.items.length > 0) {
        dispatch({ type: "setList", payload: res.data.items });
        
      }
      if(!res.loading){
        dispatch({ type: "setLoader", payload: false });
      }
      
    }
    fetchMyAPI();
  }, [ctx.userId, loadDashboard]);

  return (
    <>
      <CardsHeader name="MSO Training" parentName="Dashboard" />
      <Container className="mt-2 pl-0 pr-0" fluid>
        <Col className="card-wrapper pl-0 pr-0" xl="12">
          <Card>
            <CardHeader className="bg-transparent p-2">
              <h5 className="text-muted  ls-1">Training Resources</h5>
            </CardHeader>
            <CardBody md="12" xl="12">
              <Row className="justify-content-center">
                <Col lg="12" className="pl-1 pr-1">
                  <Row>
                    {!state.loader && state.list.length>0 ? (
                      <ModuleCard moduleList={state.list} />
                    ) : state.loader ? (
                      <Loading />
                    ) : <h4 className="pl-5">Resource is not available </h4>}
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Container>
    </>
  );
};

export default Dashboard;
