import { useReducer } from "react";
import { useHistory } from "react-router";
import Loading from "../../components/Loading";
import classnames from "classnames";
import { singUpGql } from "../../graphql/login";
import { useMutation } from "@apollo/client";

import { signUpReducer, initialState } from "../../reducer/signUpReducer";
import {
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
const SingUpForm = (propsData: { mobile: string; OTP: string }) => {
  const { push } = useHistory();
  const [singUpSubmit] = useMutation(singUpGql);
  const [state, dispatch] = useReducer(signUpReducer, initialState);

  const registerHandle = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (state.name.length < 4) {
      toast.error("The name should be as least four alphanumeric characters");
    } else if (state.pincode.length !== 6) {
      toast.error("Pin code  should be 6 digit");
    } else {
      dispatch({ type: "setLoader", payload: true });
      const res = await singUpSubmit({
        variables: {
          mobile: propsData.mobile,
          email: state.email,
          name: state.name,
          pincode: parseInt(state.pincode),
          otp: parseInt(propsData.OTP),
        },
      });
      if (res.data.SingUP.code === 401) {
        toast.error(res.data.SingUP.message);
        dispatch({ type: "setLoader", payload: false });
      } else {
        toast.success(res.data.SingUP.message);
        setTimeout(() => {
          push("/login");
        }, 2000);
      }
    }
  };
  return (
    <Row className="justify-content-center" xl="12">
      <Col lg="12" md="12">
        <Card className="bg-secondary border-0 mb-0">
          <CardBody className="p-1">
            <Form>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <InputGroup
                      className={classnames("input-group-merge", {
                        focused: state.mobileFocus,
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-globe-americas" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className="input"
                        placeholder="Mobile number"
                        type="text"
                        readOnly
                        value={propsData.mobile}
                        onChange={(e: React.FormEvent<HTMLInputElement>) =>
                          dispatch({
                            type: "setInteger",
                            fieldName: "mobile",
                            length: 10,
                            payload: e.currentTarget.value,
                          })
                        }
                        onFocus={(e: React.FormEvent<HTMLInputElement>) =>
                          dispatch({
                            type: "setFocus",
                            fieldName: "mobileFocus",
                            payload: true,
                          })
                        }
                        onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                          dispatch({
                            type: "setFocus",
                            fieldName: "mobileFocus",
                            payload: false,
                          })
                        }
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText>
                          <i className="fas fa-phone" />
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <InputGroup
                      className={classnames("input-group-merge", {
                        focused: state.nameFocus,
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-user" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className="input"
                        placeholder="Your name"
                        type="text"
                        value={state.name}
                        onChange={(e: React.FormEvent<HTMLInputElement>) =>
                          dispatch({
                            type: "setText",
                            fieldName: "name",
                            length: 50,
                            payload: e.currentTarget.value,
                          })
                        }
                        onFocus={(e: React.FormEvent<HTMLInputElement>) =>
                          dispatch({
                            type: "setFocus",
                            fieldName: "nameFocus",
                            payload: true,
                          })
                        }
                        onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                          dispatch({
                            type: "setFocus",
                            fieldName: "nameFocus",
                            payload: false,
                          })
                        }
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md="12">
                  <FormGroup>
                    <InputGroup
                      className={classnames("input-group-merge", {
                        focused: state.emailFocus,
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-envelope" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className="input"
                        placeholder="Email address"
                        type="email"
                        value={state.email}
                        onChange={(e: React.FormEvent<HTMLInputElement>) =>
                          dispatch({
                            type: "setText",
                            fieldName: "email",
                            length: 50,
                            payload: e.currentTarget.value,
                          })
                        }
                        onFocus={(e: React.FormEvent<HTMLInputElement>) =>
                          dispatch({
                            type: "setFocus",
                            fieldName: "emailFocus",
                            payload: true,
                          })
                        }
                        onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                          dispatch({
                            type: "setEmail",
                            payload: e.target.value,
                          })
                        }
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md="12">
                  <FormGroup>
                    <InputGroup
                      className={classnames("input-group-merge", {
                        focused: state.pincodeFocus,
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-map-marker" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className="input"
                        placeholder="Pin Code"
                        type="text"
                        value={state.pincode}
                        onChange={(e: React.FormEvent<HTMLInputElement>) =>
                          dispatch({
                            type: "setInteger",
                            fieldName: "pincode",
                            length: 6,
                            payload: e.currentTarget.value,
                          })
                        }
                        onFocus={(e: React.FormEvent<HTMLInputElement>) =>
                          dispatch({
                            type: "setFocus",
                            fieldName: "pincodeFocus",
                            payload: true,
                          })
                        }
                        onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                          dispatch({
                            type: "setFocus",
                            fieldName: "pincodeFocus",
                            payload: false,
                          })
                        }
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <div>
                    <button
                      type="submit"
                      className="btn btn-primary texth3"
                      onClick={(e) => registerHandle(e)}
                      style={{ padding: "5px" }}
                    >
                      Register
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Col>
      {state.loader ? <Loading /> : ""}
      <ToastContainer />
    </Row>
  );
};

export default SingUpForm;
