import { Card, CardTitle, Row, Col, CardHeader, CardBody } from "reactstrap";
import { useHistory } from "react-router";
import { ModuleTypes } from "../../models/ModuleTypes";
interface propsTypes {
  data: [ModuleTypes];
  xl: string;
}

const ModuleList = (propsData: propsTypes): JSX.Element => {
  const listData: ModuleTypes[] = propsData.data;
  const { push } = useHistory();
  const selectSubjectHandler = (e: any, vid: string) => {
    e.preventDefault();
    push("/mso/ModuleVideo/" + vid);
  };
  let listArr = [];
  for (const option of listData) {
    if (option.videoCount > 0) {
      listArr.push({ id: option.id, title: option.title });
    }
  }
  return (
    <>
      <Col md={propsData.xl} xl={propsData.xl} className="pl-0 pr-0">
        <Row xl="12">
          <Card className="mb-1  p-2">
            <CardHeader className="p-1">
              <CardTitle className="mb-1">
                <span className="custHeading"> Related Subject</span>
              </CardTitle>
            </CardHeader>
            <CardBody className="p-0 topicList">
              <ul>
                {listArr.map((data) => {
                  return (
                    <li
                      key={data.id}
                      onClick={(e) => selectSubjectHandler(e, data.id)}
                    >
                      <a href="/#" onClick={(e) => e.preventDefault()}>
                        {" "}
                        {data.title}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </CardBody>
          </Card>
        </Row>
      </Col>
    </>
  );
};

export default ModuleList;
