import { Breadcrumb, BreadcrumbItem, Container, Row, Col } from "reactstrap";
import CardHeader from "../../models/CardHeader";

function CardsHeader({ name, parentName }: CardHeader) {
  return (
    <>
      <div className="header bg-info pb-1">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-left py-1">
              <Col lg="12" xs="12">
                <h4 className="h2 text-white d-inline-block mb-0">{name}</h4>{" "}
                <Breadcrumb
                  className="d-none d-md-inline-block ml-md-1"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <i className="fas fa-home" />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      {parentName}
                    </a>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

export default CardsHeader;
