import { Col, Row } from "reactstrap";

import Loading from "../../components/Loading";
import { useState, useEffect } from "react";
import { VideoSupportListGql } from "../../graphql/Video";
import { useLazyQuery } from "@apollo/client";
const AskedQuery = (data: any): JSX.Element => {
  const [loader, SetLoader] = useState(false);
  const [queryList, SetQuery]: any = useState("");
  const [loadAskedQuery]: any = useLazyQuery(VideoSupportListGql);
  useEffect(() => {
    async function fetchQuery() {
      SetLoader(true);
      const res = await loadAskedQuery({
        variables: {
          orderBy: [{ createdAt: "desc" }],
          where: {
            appeared_by: { id: { in: [data.userId] } },
            video: { id: { in: [data.video] } },
            type: { equals: "Query" },
          },
        },
      });
      if (!res.loading) {
        if (res.data.items.length > 0) {
          SetQuery(res.data.items);
        }
        SetLoader(false);
      }
    }
    fetchQuery();
  }, [data.userId, data.video, loadAskedQuery]);
  return (
    <>
      {!loader ? (
        queryList !== "" ? (
          <div
            style={{
              height: "250px",
              overflowX: "hidden",
              overflowY: "scroll",
            }}
          >
            <b style={{ color: "rgb(223 9 38)" }}>Previous Query</b>
            {queryList.map((data: any, index: number) => {
              return (
                <Row key={index} xl="12" md="12" className="pr-4">
                  <Col
                    xl="12"
                    md="12"
                    className="m-1"
                    style={{ background: "rgb(243 226 219)" }}
                  >
                    <b style={{ color: "#00a1ff" }}>Query :</b> {data.query}
                  </Col>
                  {data.support_notes !== "" ? (
                    <Col
                      xl="12"
                      md="12"
                      className="m-1"
                      style={{ background: "#e4ede4" }}
                    >
                      <b style={{ color: "#0a8f33" }}>Reply :</b>{" "}
                      {data.support_notes}
                    </Col>
                  ) : (
                    ""
                  )}
                  <hr
                    className="mt-0"
                    style={{ border: "1px solid #efe6e6", width: "100%" }}
                  />
                </Row>
              );
            })}
          </div>
        ) : (
          ""
        )
      ) : (
        <Loading />
      )}
    </>
  );
};

export default AskedQuery;
