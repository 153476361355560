export function QuestionViewReducer(state: any, action: any) {
  switch (action.type) {
    case "setLoader": {
      return {
        ...state,
        error: "",
        loader: action.payload,
      };
    }

    case "setQuesList": {
      return {
        ...state,
        error: "",
        quesList: action.payload,
      };
    }

    case "setModule": {
      return {
        ...state,
        error: "",
        selectedModule: action.payload,
      };
    }

    case "setTestNo": {
      return {
        ...state,
        error: "",
        testNo: action.payload,
      };
    }

    case "setQuesViewType": {
      return {
        ...state,
        error: "",
        QuesViewType: action.payload,
      };
    }

    case "setShow": {
      return {
        ...state,
        error: "",
        show: action.payload,
      };
    }

    default:
      return state;
  }
}

export const initialState = {
  loader: true,
  quesList: [],
  selectedModule: "",
  testNo: "",
  QuesViewType: true,
  show: false,
};
