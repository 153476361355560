import { Table } from "reactstrap";
import { istDateTime } from "../../../../utils";
import {
  ViewModuleReportType,
  problemArea,
} from "../../../../models/ReportTypes";
const ViewModuleReport = (propData: ViewModuleReportType): JSX.Element => {
  const data = propData.data;
  return (
    <>
      <Table className="align-items-center table-flush" responsive>
        <tbody className="list">
          <tr>
            <td width="40%" align="left">
              <b>Tests Taken</b>
            </td>
            <td align="left" width="60%">
              {data.testTaken}
            </td>
          </tr>
          <tr>
            <td width="40%" align="left">
              <b>Average score of tests</b>
            </td>
            <td align="left" width="60%">
              {data.TestAvgScore}
            </td>
          </tr>
          <tr>
            <td width="40%" align="left">
              <b>Dropped tests</b>
            </td>
            <td align="left" width="60%">
              {data.testDropped}
            </td>
          </tr>

          <tr>
            <td width="40%" align="left">
              <b>Last test date</b>
            </td>
            <td align="left" width="60%">
              {data.lastTestModule.lastTestDate !== ""
                ? istDateTime(data.lastTestModule.lastTestDate)
                : ""}
            </td>
          </tr>

          <tr>
            <td width="40%" align="left">
              <b>Last test score</b>
            </td>
            <td align="left" width="60%">
              {data.lastTestModule.lastScore}
            </td>
          </tr>
          <tr>
            <td width="40%" align="left">
              <b>Problem area</b>
            </td>
            <td align="left" width="60%">
              <ul>
                {data.problemArea.map(
                  (VideoData: problemArea, index: number) => (
                    <li key={VideoData.id}>
                      <a
                        rel="noreferrer"
                        href={VideoData.video}
                        title="Click to view video"
                        target="_blank"
                      >
                        {" "}
                        {VideoData.title}{" "}
                      </a>
                    </li>
                  )
                )}
              </ul>
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default ViewModuleReport;
