export function PDFReducer(state: any, action: any) {
  switch (action.type) {
    case "setNumPages": {
      return {
        ...state,
        error: "",
        numPages: action.payload,
      };
    }
    case "setScale": {
      return {
        ...state,
        error: "",
        scale: action.payload,
      };
    }
    case "setPageNo": {
      return {
        ...state,
        error: "",
        pageNo: action.payload,
      };
    }

    default:
      return state;
  }
}

export const initialState = {
  file: "",
  numPages: 0,
  scale: 1,
  pageNo: 1,
  error: "",
};
