import { Container } from "reactstrap";

import { useEffect, useReducer, useState } from "react";

import { useParams } from "react-router";
import CardsHeader from "../../components/Header/CardsHeader";
import { useQuery, useLazyQuery } from "@apollo/client";
import { ModuleVideoGql } from "../../graphql/Video";
import { topicReducer, initialState } from "../../reducer/topicReducer";
import Loading from "../../components/Loading";
import VideoPlayer from "../../components/modulesTopic/VideoPlayer";
import SubjectTopics from "../../components/modulesTopic/SubjectTopics";
import { subjectGql } from "../../graphql/moduleTest";
import { selectTopicTypes } from "../../models/TopicTypes";
import "./video.css";

const ModuleVideo = (): JSX.Element => {
  const [state, dispatch] = useReducer(topicReducer, initialState);
  const params: any = useParams();

  const [loadSubjectTopic] = useLazyQuery(ModuleVideoGql);
  const [loadmoduleData]: any = useLazyQuery(subjectGql);
  const [vListIndx, SetListIndx] = useState<selectTopicTypes>({
    video: "",
    id: "",
    title: "",
    otherLink: "",
  });
  const { loading, data }: any = useQuery(ModuleVideoGql, {
    variables: {
      where: { module_id: { id: { in: [params.id] } } },
      orderBy: [{ seqno: "asc" }],
    },
  });
  useEffect(() => {
    async function fetchMyAPI() {
      const subRes = await loadmoduleData({
        variables: { where: { id: { equals: params.id } } },
      });
      if (!subRes.loading) {
        dispatch({ type: "setSubject", payload: subRes.data.items[0] });
        const res = await loadSubjectTopic({
          variables: {
            where: {
              module_id: { id: { in: [params.id] } },
              active: { equals: true },
            },
            orderBy: [{ seqno: "asc" }],
          },
        });
        if (!res.loading) {
          dispatch({ type: "setTopicList", payload: res.data.items });
          dispatch({ type: "setLoader", payload: false });
        }
      }
    }

    fetchMyAPI();

    if (loading === false && data) {
      dispatch({ type: "setSubject", payload: "" });
    }
  }, [loading, data, loadSubjectTopic, loadmoduleData, params.id]);
  const selectedTopics = (index: selectTopicTypes) => SetListIndx(index);
  return (
    <>
      <CardsHeader
        name={state.selectedSubjected.title}
        parentName="Training Resource"
      />
      <Container className="mt-2" fluid>
        {!state.loader && vListIndx.id !== "" ? (
          <VideoPlayer vListIndx={vListIndx} data={state.topicList} />
        ) : !state.loader && vListIndx.id === "" ? (
          <SubjectTopics selectTopic={selectedTopics} data={state.topicList} />
        ) : (
          <Loading />
        )}
      </Container>
    </>
  );
};

export default ModuleVideo;
