import "./style.css";
import { checkOption } from "../../utils";
import { QuesChoiceTypes } from "../../models/QuesViewTypes";

const QuesChoice = (OptData: QuesChoiceTypes): JSX.Element => {
  const ansCheck = checkOption(OptData.data.id, OptData.selectedAnser);
  return (
    <>
      <li className={ansCheck}>{OptData.data.statement}</li>
    </>
  );
};

export default QuesChoice;
