import { gql } from "@apollo/client";
const startTest = gql`
  mutation StartTest($module_id: ID!, $appeared_by: ID!, $language: String) {
    StartTest(
      module_id: $module_id
      appeared_by: $appeared_by
      language: $language
    ) {
      message

      questionList {
        id
        test {
          id
        }
        question_no
        module {
          title
          id
          is_final
        }
        question {
          id
          type
          statement {
            document(hydrateRelationships: true)
          }
          Queschoice {
            id
            statement
          }
        }
        selectedAnser {
          id
        }
        textAnswer
      }
    }
  }
`;

const saveAnswer = gql`
  mutation ($data: testQuestionUpdateInput!, $id: ID!) {
    item: UpdateAnswer(where: { id: $id }, data: $data) {
      message
    }
  }
`;

const updateTest = gql`
  mutation ApproveTestAuto(
    $TestID: ID!
    $is_final: Boolean!
    $test_status: String!
  ) {
    ApproveTestAuto(
      TestID: $TestID
      is_final: $is_final
      test_status: $test_status
    ) {
      message
    }
  }
`;

const updateScore = gql`
  mutation ($data: testQuestionUpdateInput!, $id: ID!) {
    item: updatetestQuestion(where: { id: $id }, data: $data) {
      id
    }
  }
`;

const subjectGql = gql`
  query (
    $where: subjectWhereInput
    $take: Int
    $skip: Int
    $orderBy: [subjectOrderByInput!]
  ) {
    items: subjects(
      where: $where
      take: $take
      skip: $skip
      orderBy: $orderBy
    ) {
      id
      title
      is_final
    }
  }
`;

export { startTest, saveAnswer, updateTest, updateScore, subjectGql };
