import { Button, Card, CardBody, CardTitle, Row, Col } from "reactstrap";

import { useHistory } from "react-router";
import { ModuleTypes } from "../../models/ModuleTypes";

interface propsTypes {
  moduleList: [ModuleTypes];
}

const ModuleCard = (propsData: propsTypes): JSX.Element => {
  const cardData: ModuleTypes[] = propsData.moduleList;
  const { push } = useHistory();
  const ModuleVideo = (
    e: React.MouseEvent<HTMLUnknownElement>,
    mid: String
  ) => {
    e.preventDefault();
    push("/mso/ModuleVideo/" + mid);
  };
  const CompetedTest = (
    e: React.MouseEvent<HTMLUnknownElement>,
    mid: String
  ) => {
    e.preventDefault();
    push("/mso/CompletedTest/" + mid);
  };
  const startTest = (e: React.MouseEvent<HTMLButtonElement>, mid: String) => {
    e.preventDefault();
    push("/mso/ModuleTest/" + mid);
  };
  return (
    <>
      {cardData.map((data, index: number) => (
        <Col xl="4" key={data.id}>
          <Card style={{ border: "1px solid #e3d9d9" }}>
            <CardBody style={{ padding: ".5rem", minHeight: "280px" }}>
              <CardTitle
                className="mb-1"
                tag="h5"
                style={{ textAlign: "center" }}
              >
                {data.title}
              </CardTitle>

              <table cellPadding="5" key={`tbl${data["id"]}`}>
                <tbody>
                  {!data.is_final ? (
                    <tr>
                      <td className="p-2">
                        <h4>No. of Topics</h4>
                      </td>
                      <td className="p-2">
                        <a
                          className="avatar rounded-circle"
                          href="#pablo"
                          style={{
                            background: "#339499",
                            height: "45px",
                            width: "45px",
                          }}
                          onClick={(e) =>
                            data.videoCount > 0
                              ? ModuleVideo(e, data.id)
                              : e.preventDefault
                          }
                        >
                          {data.videoCount}
                        </a>
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td className="p-2" colSpan={2}></td>
                    </tr>
                  )}
                  <tr>
                    <td className="p-2">
                      <h4>No. of Questions</h4>
                    </td>
                    <td className="p-2 pl-3">
                      <span
                        className="avatar"
                        style={{
                          background: "rgb(97 102 102)",
                          height: "35px",
                          width: "35px",
                        }}
                        onClick={(e) => e.preventDefault()}
                      >
                        {data.noofques}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="p-2">
                      <h4>Completed Test</h4>
                    </td>
                    <td className="p-2">
                      <a
                        className="avatar rounded-circle"
                        href="#pablo"
                        style={{
                          background: "rgb(47 118 76)",
                          height: "45px",
                          width: "45px",
                        }}
                        onClick={(e) =>
                          data.completedTest > 0
                            ? CompetedTest(e, data.id)
                            : e.preventDefault()
                        }
                      >
                        {data.completedTest}
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              {data.questionCount > 0 &&
              data.noofques > 0 &&
              data.testStatus > 0 ? (
                <Row lg="12">
                  <Col lg="12" xl="12" className="text-center pt-3">
                    <Button
                      className="mb-2"
                      color="btn btn-outline-default p-2"
                      onClick={(e) => startTest(e, data.id)}
                    >
                      {data.runningTest === 0 ? "Start Test" : "Continue Test"}
                    </Button>
                  </Col>
                </Row>
              ) : (
                ""
              )}
            </CardBody>
          </Card>
        </Col>
      ))}
    </>
  );
};
export default ModuleCard;
