import { Table } from "reactstrap";
import { Pagination } from "../../components/Table/pagination";
const TableList = ({
  TableData,
  Columns,
  page,
  fetchData,
  dataLength,
  noOfDataPerPage,
}: any): JSX.Element => {
  return (
    <>
      <Table className="align-items-center table-flush" responsive striped>
        <thead className="thead-light">
          <tr>
            {Columns.map((data: any, index: number) => (
              <th key={index}>{data.name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {TableData.map((data: any, index: number) => (
            <tr key={data.id}>
              {Columns.map((colData: any, ColIndex: number) => (
                <td key={`${data.id}${ColIndex}`}>{data[colData.selector]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      {dataLength > noOfDataPerPage ? (
        <Pagination
          dataLength={dataLength}
          postsPerPage={noOfDataPerPage}
          page={page}
          fetchData={fetchData}
        />
      ) : (
        ""
      )}
    </>
  );
};
export default TableList;
