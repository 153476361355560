import {
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Input,
} from "reactstrap";
import { useContext } from "react";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { LoginContext } from "../../context/loginContext";
import { ToastContainer, toast } from "react-toastify";
import { changeStatusGql } from "../../graphql/Video";
import Loading from "../../components/Loading";
import { useState } from "react";
import { ApproveVideoTypes } from "../../models/VideoSupportType";
const ApproveVideoRequest = (data: ApproveVideoTypes): JSX.Element => {
  const ctx = useContext(LoginContext);
  const history = useHistory();
  const [note, setNote] = useState(data.detail.support_notes);
  const [loader, SetLoader] = useState(false);
  const [Approve] = useMutation(changeStatusGql);

  const inputHandler = async (event: React.FocusEvent<HTMLInputElement>) => {
    setNote(event.target.value);
  };

  const approveHandler = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    SetLoader(true);
    const res = await Approve({
      variables: {
        data: {
          support_status: "Completed",
          support_notes: note,
          reviewed_by: { connect: { id: ctx.userId } },
          supportedAt: new Date().toISOString(),
        },
        id: data.detail.id,
      },
    });
    if (res.data.item.support_status === "Completed") {
      toast.success("Replied Successfully");

      setTimeout(() => {
        SetLoader(false);
        history.goBack();
      }, 2000);
    }
  };

  return (
    <>
      {!loader ? (
        <Modal isOpen={data.show}>
          <ModalHeader>Approve Video Support Request</ModalHeader>
          <ModalBody>
            {data.detail.query !== "" ? (
              <div className="mb-2">
                <b>Query:</b> {data.detail.query}
              </div>
            ) : (
              ""
            )}
            <div className="custom-control  mb-3">
              <Input
                id="reviewerNote"
                name="reviewerNote"
                value={note}
                placeholder="Enter reply notes"
                onChange={inputHandler}
                rows="3"
                type="textarea"
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={(e) => approveHandler(e)}>
              Approve
            </Button>
            <Button color="secondary" onClick={data.handleClose}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      ) : (
        <Loading />
      )}
      <ToastContainer />
    </>
  );
};

export default ApproveVideoRequest;
