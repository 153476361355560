import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  CardBody,
  Col,
} from "reactstrap";

import { useParams } from "react-router";
import { TestViewQgl } from "../../../graphql/Test";
import { useLazyQuery } from "@apollo/client";
import Loading from "../../../components/Loading";
import CardsHeader from "../../../components/Header/CardsHeader";
import { useEffect, useReducer, useContext } from "react";
import Back from "../../../components/Back/back";
import QuesView from "../../../components/Question/View";
import ApproveTest from "../../../components/Question/ApproveTest";
import { QuesViewDataTypes } from "../../../models/QuesViewTypes";
import {
  QuestionViewReducer,
  initialState,
} from "../../../reducer/QuestionViewReducer";
import { LoginContext } from "../../../context/loginContext";
import LanguageSelector from "../../../components/DropDown/LanguageSelector";
const TestQuestions = (): JSX.Element => {
  const ctx = useContext(LoginContext);
  const [state, dispatch] = useReducer(QuestionViewReducer, initialState);
  const params: { id: string; status: string } = useParams();
  const [loadQuestion] = useLazyQuery(TestViewQgl);
  const handleClose = () => dispatch({ type: "setShow", payload: false });
  const handleShow = () => dispatch({ type: "setShow", payload: true });
  const setToggle = (e: React.MouseEvent<HTMLButtonElement>) => {
    !state.QuesViewType
      ? dispatch({ type: "setQuesViewType", payload: true })
      : dispatch({ type: "setQuesViewType", payload: false });
  };
  useEffect(() => {
    async function fetchMyAPI() {
      const res = await loadQuestion({
        variables: { id: params.id, language: ctx.language },
      });
      if (!res.loading) {
        if (res.data.TestQuestion.questionList.length > 0) {
          dispatch({
            type: "setQuesList",
            payload: res.data.TestQuestion.questionList,
          });
          dispatch({
            type: "setTestNo",
            payload: res.data.TestQuestion.questionList[0].test.test_no,
          });
          dispatch({
            type: "setModule",
            payload: res.data.TestQuestion.questionList[0].module.title,
          });
        }
        dispatch({ type: "setLoader", payload: false });
      }
    }
    fetchMyAPI();
  }, [loadQuestion, params.id, ctx.language]);

  return (
    <>
      <CardsHeader name="MSO Traning" parentName="Test Question" />
      {!state.loader ? (
        <Container className="mt-2 pl-0 pr-0" fluid>
          <Col xl="12" className="pl-0 pr-0">
            <Card>
              <CardHeader>
                <Row xl="12">
                  <Col xl="6">
                    <h5 className="h3 mb-0">
                      {state.selectedModule} (Test-{state.testNo})
                    </h5>
                    <LanguageSelector />
                  </Col>
                  {params.status === "review" ? (
                    <Col xl="3">
                      <Button variant="primary" onClick={handleShow}>
                        Approve
                      </Button>
                    </Col>
                  ) : (
                    ""
                  )}
                  {params.status === "view" ? (
                    <Col xl="3">
                      <Button
                        className={
                          state.QuesViewType
                            ? "btn btn-success"
                            : "btn btn-danger"
                        }
                        onClick={(e) => setToggle(e)}
                      >
                        {!state.QuesViewType ? "Not Attempted" : "Attempted"}
                      </Button>
                    </Col>
                  ) : (
                    ""
                  )}
                  <Col className="text-right" xl="3">
                    <Back />
                  </Col>
                </Row>
              </CardHeader>

              <CardBody
                className="p-0 pb-9 pl-2 QuesView"
                style={{
                  maxHeight: "500px",
                  overflowX: "hidden",
                  overflowY: "auto",
                }}
              >
                {state.quesList.map((data: QuesViewDataTypes) => (
                  <QuesView
                    key={data.id}
                    data={data}
                    QuesViewType={state.QuesViewType}
                    status={params.status}
                  />
                ))}
              </CardBody>
            </Card>
            {params.status === "review" ? (
              <ApproveTest
                show={state.show}
                handleClose={handleClose}
                test={state.selectedModule + " " + state.testNo}
                id={params.id}
                key={params.id}
              />
            ) : (
              ""
            )}
          </Col>
        </Container>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default TestQuestions;
