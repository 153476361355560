import {
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Input,
} from "reactstrap";

import { useLazyQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { TestNoteGql, ApproveTestGql } from "../../graphql/Test";
import Loading from "../../components/Loading";
import { useEffect, useState, useContext } from "react";
import { LoginContext } from "../../context/loginContext";
import { ToastContainer, toast } from "react-toastify";
interface propsTypes {
  show: boolean;
  test: string;
  id: string;
  handleClose: () => void;
}
const ApproveTest = (data: propsTypes): JSX.Element => {
  const ctx = useContext(LoginContext);
  const history = useHistory();
  const [note, setNote] = useState("");
  const [loader, SetLoader] = useState(true);
  const [Approve] = useMutation(ApproveTestGql);
  const [loadTestNote] = useLazyQuery(TestNoteGql);
  const inputHandler = async (event: React.FocusEvent<HTMLInputElement>) => {
    setNote(event.target.value);
  };

  const approveHandler = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    SetLoader(true);
    const res = await Approve({
      variables: {
        TestID: data.id,
        review_notes: note,
        reviewed_by: ctx.userId,
      },
    });
    if (res.data.ApproveTest.message === "success") {
      toast.success("Approved Successfully");

      setTimeout(() => {
        SetLoader(false);
        history.goBack();
      }, 2000);
    }
  };

  useEffect(() => {
    async function fetchAPI() {
      SetLoader(true);
      const res = await loadTestNote({
        variables: {
          orderBy: [{ createdAt: "desc" }],
          where: {
            id: { equals: data.id },
          },
          take: 1,
          skip: 0,
        },
      });
      if (!res.loading) {
        if (res.data.tests.length > 0) {
          setNote(res.data.tests[0].review_notes);
        }
        SetLoader(false);
      }
    }
    fetchAPI();
  }, [loadTestNote, data.id]);

  return (
    <>
      {!loader ? (
        <Modal isOpen={data.show}>
          <ModalHeader>Approve Test ({data.test})</ModalHeader>
          <ModalBody>
            <div className="custom-control  mb-3">
              <Input
                id="reviewerNote"
                name="reviewerNote"
                value={note}
                onChange={inputHandler}
                rows="5"
                type="textarea"
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={(e) => approveHandler(e)}>
              Approve
            </Button>
            <Button color="secondary" onClick={data.handleClose}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      ) : (
        <Loading />
      )}
      <ToastContainer />
    </>
  );
};

export default ApproveTest;
