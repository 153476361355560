import { gql } from "@apollo/client";
const TestQgl: any = gql`
  query (
    $where: testWhereInput
    $take: Int!
    $skip: Int!
    $orderBy: [testOrderByInput!]
  ) {
    items: tests(where: $where, take: $take, skip: $skip, orderBy: $orderBy) {
      id
      test_no
      appeared_by {
        id
        label: name
        __typename
      }
      reviewed_by {
        id
        label: name
        __typename
      }
      module_id {
        id
        label: title
        __typename
      }
      attemptedQuestion
      obtainedMarks
      finalisedAt
      reviewedAt
      reviewer_status
      createdAt
      __typename
    }
    count: testsCount(where: $where)
  }
`;

const TestQglCount: any = gql`
  query ($where: testWhereInput) {
    count: testsCount(where: $where)
  }
`;

const TestViewQgl: any = gql`
  query TestQuestion($id: ID!, $language: String) {
    TestQuestion(id: $id, language: $language) {
      message
      questionList {
        id
        isAttempted
        test {
          id
          test_no
        }
        question_no
        module {
          title
        }
        question {
          id
          type
          statement {
            document(hydrateRelationships: true)
          }
          Queschoice {
            id
            statement
            correct
          }
        }
        selectedAnser {
          id
          statement
          correct
        }
        score
        textAnswer
      }
    }
  }
`;

const TestNoteGql: any = gql`
  query (
    $where: testWhereInput
    $take: Int!
    $skip: Int!
    $orderBy: [testOrderByInput!]
  ) {
    tests(where: $where, take: $take, skip: $skip, orderBy: $orderBy) {
      id
      review_notes
    }
  }
`;
const ApproveTestGql: any = gql`
  mutation ApproveTest($TestID: ID!, $review_notes: String, $reviewed_by: ID!) {
    ApproveTest(
      TestID: $TestID
      review_notes: $review_notes
      reviewed_by: $reviewed_by
    ) {
      message
    }
  }
`;
export { TestQgl, TestViewQgl, TestQglCount, TestNoteGql, ApproveTestGql };
