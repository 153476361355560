import { gql } from "@apollo/client";

const GetMSODashboardGql: any = gql`
  query ($take: Int, $skip: Int, $uid: ID, $orderBy: [subjectOrderByInput!]) {
    items: subjects(
      where: { active: { equals: true } }
      take: $take
      skip: $skip
      orderBy: $orderBy
    ) {
      id
      title
      noofques
      is_final
      videoCount
      questionCount
      completedTest(appeared_by: $uid)
      runningTest(appeared_by: $uid)
      testStatus(appeared_by: $uid)
    }
  }
`;

export { GetMSODashboardGql };
