import React, { useEffect, useState, SyntheticEvent } from "react";
import "./pagination.css";
const totalPagesCount = (totalData: number, postsPerPage: number): number => {
  return Math.ceil(Math.abs(totalData / postsPerPage));
};

const filterPages = (visiblePages: number[], totalPages: number): any => {
  return visiblePages.filter((page) => page <= totalPages);
};

const getVisiblePages = (pageNo: number, total: number) => {
  //alert('ppppp'+pageNo+'ooooo');
  if (total < 7) {
    return filterPages([1, 2, 3, 4, 5], total);
  } else {
    if (pageNo % 5 >= 0 && pageNo > 4 && pageNo + 2 < total) {
      return [1, pageNo - 1, pageNo, pageNo + 1, total];
    } else if (pageNo % 5 >= 0 && pageNo > 4 && pageNo + 2 >= total) {
      return [1, total - 3, total - 2, total - 1, total];
    } else {
      return [1, 2, 3, 4, 5, total];
    }
  }
};

interface PaginationProps {
  dataLength: number;
  postsPerPage: number;
  page: number;
  fetchData: any;
}

export const Pagination: React.FC<PaginationProps> = ({
  dataLength,
  postsPerPage,
  page,
  fetchData,
}) => {
  const pages: number = totalPagesCount(dataLength, postsPerPage);
  const [visiblePages, setVisiblePages] = useState(getVisiblePages(1, pages));
  const previousText = "Previous";
  const nextText = "Next";

  useEffect(() => {
    const vps = getVisiblePages(page, pages);
    setVisiblePages(filterPages(vps, pages));
  }, [page, pages]);

  const changePage = (e: SyntheticEvent, p: number) => {
    if (p === page + 1) {
      return;
    }

    fetchData(p - 1);
  };

  const activePage: number = page + 1;

  return (
    <div className="Table__pagination">
      <div className="Table__prevPageWrapper">
        <button
          className="Table__pageButton"
          onClick={(e: SyntheticEvent) => {
            if (activePage === 1) return;
            changePage(e, activePage - 1);
          }}
          disabled={activePage === 1}
        >
          {previousText}
        </button>
      </div>
      <div className="Table__visiblePagesWrapper">
        {visiblePages.map((p: number, index: number, array: number[]) => {
          return (
            <button
              key={p}
              className={
                activePage === p
                  ? "Table__pageButton Table__pageButton--active"
                  : "Table__pageButton"
              }
              onClick={(e: SyntheticEvent) => changePage(e, p)}
            >
              {array[index - 1] + 2 < p ? `...${p}` : p}
            </button>
          );
        })}
      </div>
      <div className="Table__nextPageWrapper">
        <button
          className="Table__pageButton"
          onClick={(e: SyntheticEvent) => {
            if (activePage === pages) return;
            changePage(e, activePage + 1);
          }}
          disabled={activePage === pages}
        >
          {nextText}
        </button>
      </div>
    </div>
  );
};
