import {
  UncontrolledDropdown,
  DropdownToggle,
  Col,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";

interface propsType {
  testUpdate: (e: React.MouseEvent<HTMLButtonElement>, status: String) => void;
}
const TestAction = (testData: propsType): JSX.Element => {
  return (
    <>
      <Col className="col-auto">
        <UncontrolledDropdown>
          <DropdownToggle size="sm" color="neutral" className="mr-0">
            <i className="fas fa-ellipsis-h" />
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem
              href="#pablo"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                testData.testUpdate(e, "Completed")
              }
            >
              Complete Test
            </DropdownItem>
            <DropdownItem
              href="#pablo"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                testData.testUpdate(e, "Dropped")
              }
            >
              Drop Test
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Col>
    </>
  );
};

export default TestAction;
