import * as React from "react";
import Downshift from "downshift";
import styled from "styled-components";
import "./styles.css";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";

type OptionType = {
  id?: string;
  label?: string;
};

interface IDropdownProps {
  options: OptionType[];
  onChange?: (selectedItem: string) => void;
  placeholder?: String;
}

const Input: any = styled.input`
  width: 90%;

  height: 30px;
  border: 1px solid black;
`;

const Menu: any = styled.ul`
  max-height: 180px;
  overflow-y: auto;
  margin: 0;
  border-top: 0;
  margin-top: 2px;
  background: white;
  position: absolute;
  z-index: 1000;
  list-style: none;
  padding: 0;
  left: 5px;
  width: 100%;
`;
const Item: any = styled.li`
  position: relative;
  cursor: pointer;
  display: block;

  height: auto;
  textalign: left;
  bordertop: none;
  lineheight: 1em;
  color: rgba(0, 0, 0, 0.87);
  fontsize: 1rem;
  texttransform: none;
  fontweight: 400;
  boxshadow: none;
  padding: 0.2rem 0.1rem;
  whitespace: normal;
  wordwrap: normal;
`;

const Button: any = styled.button`
  appearance: auto;
  writing-mode: horizontal-tb !important;
  font-style: ;
  font-variant-ligatures: ;
  font-variant-caps: ;
  font-variant-numeric: ;
  font-variant-east-asian: ;
  font-weight: ;
  font-stretch: ;
  font-size: ;
  font-family: ;
  text-rendering: auto;
  color: -internal-light-dark(black, white);
  letter-spacing: normal;
  word-spacing: normal;
  line-height: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: center;
  align-items: flex-start;
  cursor: pointer;
  box-sizing: border-box;
  background-color: -internal-light-dark(rgb(239, 239, 239), rgb(59, 59, 59));
  margin: 0em;
  padding: 1px 6px;
  border-width: 2px;
  border-style: outset;
  border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
  border-image: initial;
`;

const InputContainer: any = styled.div`
  display: flex;
`;

const Dropdown: React.FC<IDropdownProps> = ({
  options,
  onChange,
  placeholder,
}: any) => {
  return (
    <Downshift
      onChange={(selectedItem) => onChange(selectedItem ? selectedItem.id : "")}
      itemToString={(item) => (item ? item.label : "")}
    >
      {({
        getInputProps,
        getMenuProps,
        getItemProps,
        getToggleButtonProps,
        isOpen,
        highlightedIndex,
        inputValue,
        openMenu,
        selectItem,
      }) => (
        <div className="comboBox">
          <InputContainer>
            <Input
              {...getInputProps({
                onFocus: () => {
                  if (!isOpen) {
                    openMenu();
                  }
                },
              })}
              placeholder={placeholder}
            />

            <Button {...getToggleButtonProps()}>
              {isOpen ? <TiArrowSortedUp /> : <TiArrowSortedDown />}
            </Button>
            <Button
              tabIndex={-1}
              onClick={() => {
                selectItem(null);
              }}
              aria-label="clear selection"
            >
              &#215;
            </Button>
          </InputContainer>
          <Menu {...getMenuProps()} className={isOpen ? "borderDropDown" : ""}>
            {isOpen &&
              options
                .filter(
                  (item: any) =>
                    !inputValue ||
                    item.label.toLowerCase().includes(inputValue.toLowerCase())
                )
                .map((item: any, index: any) => (
                  <Item
                    {...getItemProps({
                      style: {
                        backgroundColor:
                          index === highlightedIndex ? "lightgray" : "",
                      },
                      key: item.id,
                      item,
                      index,
                    })}
                  >
                    {item.label}
                  </Item>
                ))}
          </Menu>
        </div>
      )}
    </Downshift>
  );
};

export default Dropdown;
