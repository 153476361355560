import { Card, Col } from "reactstrap";
import { TopicListTypes } from "../../models/TopicTypes";
import { youtubeCode } from "../../utils";

const VideoList = (propsData: TopicListTypes): JSX.Element => {
  return (
    <>
      {propsData.data.map((data, index: number) => (
        <Col md={propsData.xl} xl={propsData.xl} key={data.id} className="pl-2 pr-2">
          <Card
            className={`mb-1 mt-2   videoLink ${
              propsData.vListIndx !== "" && propsData.vListIndx === data.id
                ? "selectedVideo"
                : ""
            } `}
            onClick={(e) =>
              propsData.linkClick(
                e,
                data.video,
                data.id,
                data.title,
                data.additionalresource
              )
            }
          >
            <Col
              xl="12"
              className="order-md-1 pl-0 pr-0"
              md="12"
              style={{ paddingLeft: "2px", paddingRight: "2px" }}
            >
              <img
                className="img-fluid rounded"
                src={`https://i.ytimg.com/vi_webp/${youtubeCode(
                  data.video
                )}/mqdefault.webp`}
                alt={data.title}
              />
            </Col>
            <Col xl="12" className="order-md-2" md="12">
              <div
                className={`mb-1 linkTitle p-1`}
                style={{ textAlign: "justify", minHeight: propsData.minHeight }}
              >
                {data.title}
              </div>
            </Col>
          </Card>
        </Col>
      ))}
    </>
  );
};

export default VideoList;
