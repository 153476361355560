export function videoReducer(state: any, action: any) {
  switch (action.type) {
    case "setLoader": {
      return {
        ...state,
        error: "",
        loader: action.payload,
      };
    }

    case "setQueryLoader": {
      return {
        ...state,
        error: "",
        queryLoader: action.payload,
      };
    }

    case "setCurrentPage": {
      return {
        ...state,
        error: "",
        currentPage: action.payload,
      };
    }

    case "setVideoList": {
      return {
        ...state,
        error: "",
        videoList: action.payload,
      };
    }

    case "setDataLength": {
      return {
        ...state,
        error: "",
        dataLength: action.payload,
      };
    }
    case "setVideoCode": {
      return {
        ...state,
        error: "",
        videoCode: action.payload,
      };
    }

    case "setModule": {
      return {
        ...state,
        error: "",
        moduleName: action.payload,
      };
    }

    case "setPlayerOtp": {
      return {
        ...state,
        error: "",
        playerOtp: action.payload,
      };
    }
    case "setQuery": {
      return {
        ...state,
        error: "",
        query: action.payload,
      };
    }

    case "setDetail": {
      return {
        ...state,
        error: "",
        detail: action.payload,
      };
    }

    default:
      return state;
  }
}

export const initialState = {
  loader: true,
  queryLoader: false,
  videoCode: "",
  playerOtp: "",
  detail: "",
  error: "",
  query: "",
};
