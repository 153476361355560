import { Table } from "reactstrap";
import { istDateTime } from "../../../../utils";
import {
  ViewIndividualReportType,
  moduleProblem,
} from "../../../../models/ReportTypes";
const ViewIndividualReport = (
  propData: ViewIndividualReportType
): JSX.Element => {
  const data = propData.data;
  return (
    <>
      <Table className="align-items-center table-flush" responsive>
        <tbody className="list">
          <tr>
            <td width="40%" align="left">
              <b>Completed tests</b>
            </td>
            <td align="left" width="60%">
              {data.testCompleted}
            </td>
          </tr>
          <tr>
            <td width="40%" align="left">
              <b>Dropped tests</b>
            </td>
            <td align="left" width="60%">
              {data.testDropped}
            </td>
          </tr>
          <tr>
            <td width="40%" align="left">
              <b>On-going tests</b>
            </td>
            <td align="left" width="60%">
              {data.testOngoing}
            </td>
          </tr>
          <tr>
            <td width="40%" align="left">
              <b>Top 3 lowest scoring subjects</b>
            </td>
            <td align="left" width="60%">
              <ul>
                {data.moduleProblem.map(
                  (prblData: moduleProblem, index: number) => (
                    <li key={prblData.id}>{prblData.title}</li>
                  )
                )}
              </ul>
            </td>
          </tr>
          <tr>
            <td width="40%" align="left">
              <b>Last test date</b>
            </td>
            <td align="left" width="60%">
              {data.lastTestModule.lastTestDate !== ""
                ? istDateTime(data.lastTestModule.lastTestDate)
                : ""}
            </td>
          </tr>
          <tr>
            <td width="40%" align="left">
              <b>Last Test Module</b>
            </td>
            <td align="left" width="60%">
              <b>{data.lastTestModule.title}</b>
            </td>
          </tr>
          <tr>
            <td width="40%" align="left">
              <b>Last test score</b>
            </td>
            <td align="left" width="60%">
              {data.lastTestModule.lastScore}
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default ViewIndividualReport;
