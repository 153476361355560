import React from "react";

interface IState {
  userName: string;
  role: string;
  userId: string;
  language: string;
  onLogin?: (
    userName: string,
    userId: string,
    role: string,
    language: string
  ) => void;
}

const initialState: IState = {
  userName: "",
  role: "",
  userId: "",
  language: "",
};

export const LoginContext = React.createContext<IState>(initialState);

interface IAuthContextProviderProps {
  isParent?: boolean;
}

export const AuthContextProvider: React.FC<IAuthContextProviderProps> = (
  props
) => {
  const [userName, setUsername] = React.useState(initialState.userName);
  const [userId, setUserId] = React.useState(initialState.userId);
  const [role, setUserRole] = React.useState(initialState.role);
  const [language, setLanguage] = React.useState(initialState.language);

  let value: IState = { userName: "", role: "", userId: "", language: "" };
  value = {
    userName,
    role,
    userId,
    language,
    onLogin: (
      userName: string,
      userId: string,
      role: string,
      language: string
    ) => {
      setUsername(userName);
      setUserId(userId);
      setUserRole(role);
      setLanguage(language);
    },
  };

  return (
    <LoginContext.Provider value={value}>
      {props.children}
    </LoginContext.Provider>
  );
};
