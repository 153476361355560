import { useState } from "react";
import { Col } from "reactstrap";
import { updateScore } from "../../graphql/moduleTest";
import { useMutation } from "@apollo/client";
const GiveMarks = (data: { id: string; score: string }): JSX.Element => {
  const [marks, setMarks] = useState(data.score);
  const [saveQuesAns] = useMutation(updateScore);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;
    const value = e.target.value;
    if (rx_live.test(e.target.value)) {
      setMarks(value);
    }
  };

  const inputHandler = async (event: React.FocusEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    const res = await saveQuesAns({
      variables: {
        data: {
          score: inputValue,
        },
        id: data.id,
      },
    });
    if (res.data.item.id === "") {
      alert("Marks is not saved");
    }
  };

  return (
    <>
      <Col xl="12">
        <div>
          <input
            placeholder="Enter marks"
            type="text"
            name="marks"
            id="marks"
            maxLength={3}
            pattern="[+-]?\d+(?:[.,]\d+)?"
            onChange={handleChange}
            value={marks}
            onBlur={inputHandler}
            className={`form-control}`}
          />
        </div>
        <hr className="mt-1 mb-1" />
      </Col>
    </>
  );
};

export default GiveMarks;
