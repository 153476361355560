import ReactDOM from "react-dom";
import { BrowserRouter, Redirect } from "react-router-dom";
// plugins styles from node_modules

import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "select2/dist/css/select2.min.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// plugins styles downloaded
import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/scss/argon-dashboard-pro-react.scss?v1.2.0";
import 'react-toastify/dist/ReactToastify.min.css';
import Routes from "./components/Layout/Routes";
// // core styles
import { AuthContextProvider } from "./context/loginContext";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const client = new ApolloClient({
  uri: process.env.REACT_APP_BACKEND_URL,
  credentials: "include",
  cache: new InMemoryCache({ addTypename: false, resultCaching: false }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  },
});
const App = ()=>{
return(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <AuthContextProvider>
        <Routes />
      </AuthContextProvider>
      <Redirect from="*" to="/login" />
    </BrowserRouter>
  </ApolloProvider>
);
}
export default App;