import { gql } from "@apollo/client";

const ModuleVideoGql: any = gql`
  query (
    $where: topicWhereInput
    $take: Int
    $skip: Int
    $orderBy: [topicOrderByInput!]
  ) {
    items: topics(where: $where, take: $take, skip: $skip, orderBy: $orderBy) {
      id
      title
      video
      module_id {
        id
        title
      }
      additionalresource {
        id
        title
        attachedFile {
          filename
          filesize
          ref
          url
        }
        content {
          document(hydrateRelationships: true)
        }
        active
        url
      }
    }
  }
`;
const VideoSupportGql: any = gql`
  mutation ($data: videoSupportCreateInput!) {
    createvideoSupport(data: $data) {
      id
    }
  }
`;

const changeStatusGql: any = gql`
  mutation ($data: videoSupportUpdateInput!, $id: ID!) {
    item: updatevideoSupport(where: { id: $id }, data: $data) {
      support_status
      support_notes
      supportedAt
    }
  }
`;

const VideoSupportListGql: any = gql`
  query (
    $where: videoSupportWhereInput
    $take: Int
    $skip: Int
    $orderBy: [videoSupportOrderByInput!]
  ) {
    items: videoSupports(
      where: $where
      take: $take
      skip: $skip
      orderBy: $orderBy
    ) {
      id
      appeared_by {
        id
        name
        mobile
      }
      reviewed_by {
        id
        name
      }
      startAt
      module {
        id
        title
      }
      video {
        id
        title
        video
      }
      support_notes
      createdAt
      supportedAt
      support_status
      query
    }
    count: videoSupportsCount(where: $where)
  }
`;

export {
  ModuleVideoGql,
  VideoSupportGql,
  VideoSupportListGql,
  changeStatusGql,
};
