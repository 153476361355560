import { IsLoggedInGql } from "../../graphql/login";
import { useLazyQuery } from "@apollo/client";
import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router";
import { LoginContext } from "../../context/loginContext";
import Layout from "../../components/Layout/Layout";
import Loading from "../../components/Loading";

function Routes(): JSX.Element {
  const { push } = useHistory();
  const { onLogin } = useContext(LoginContext);
  const [loader, setLoader] = useState(true);
  const [loadData] = useLazyQuery(IsLoggedInGql);

  useEffect(() => {
    async function loadAPI() {
      const res = await loadData();

      const path = window.location.pathname;
      if (!res.loading && onLogin) {
        const resData = res.data.UserIsLoggedIn;
        if (resData.message === "Loggedin" && path === "/login") {
          push("/" + resData.role + "/dashboard");
        } else if (
          window.location.pathname !== "/login" &&
          resData.message !== "Loggedin"
        ) {
          push("/login");
        }
        onLogin(
          resData.name,
          resData.userId,
          resData.role,
          resData.preferlanguage
        );
        setLoader(false);
      }
    }

    loadAPI();
  }, [onLogin, push, loadData]);

  return <>{!loader ? <Layout /> : <Loading />}</>;
}

export default Routes;
