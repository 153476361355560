import { Col } from "reactstrap";
import QuesChoice from "./QuesChoice";
import { QuesViewDataTypes } from "../../models/QuesViewTypes";

interface propsType {
  data: QuesViewDataTypes;
}
const ChoiceView = (ViewData: propsType): JSX.Element => {
  const data = ViewData.data;
  const quesOption = data.question.Queschoice;
  let correctAns = [];
  for (const value of quesOption) {
    if (value.correct) {
      correctAns.push(value);
    }
  }
  

  return (
    <>
      <Col xl="12">
        <ul className="mb-1" style={{ marginTop: "0px" }}>
          {quesOption.map((qData, index: number) => (
            <QuesChoice
              key={index}
              data={qData}
              selectedAnser={data.selectedAnser}
              id={data.id}
            />
          ))}
        </ul>
      </Col>
      <Col xl="12">
        <h5 className="rightAnsTitle">Right Answer</h5>
        <ul className="mb-1" style={{ marginTop: "0px" }}>
          {correctAns.map((qData, index: number) => (
            <li key={index} className="right">
              {qData.statement}
            </li>
          ))}
        </ul>
      </Col>
    </>
  );
};

export default ChoiceView;
