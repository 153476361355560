import { gql } from "@apollo/client";

const MSOListGQL: any = gql`
  query (
    $where: userWhereInput
    $take: Int!
    $skip: Int!
    $orderBy: [userOrderByInput!]
  ) {
    items: users(where: $where, take: $take, skip: $skip, orderBy: $orderBy) {
      id
      name
    }
  }
`;

const ModuleListGQL: any = gql`
  query (
    $where: subjectWhereInput
    $take: Int!
    $skip: Int!
    $orderBy: [subjectOrderByInput!]
  ) {
    items: subjects(
      where: $where
      take: $take
      skip: $skip
      orderBy: $orderBy
    ) {
      id
      title
    }
  }
`;

const MsoModuleReportGQL: any = gql`
  query msoModuleReport($msoID: ID!, $moduleID: ID!) {
    msoModuleReport(msoID: $msoID, moduleID: $moduleID) {
      testTaken
      TestAvgScore
      testDropped
      lastTestModule {
        id
        title
        lastTestDate
        lastScore
      }
      problemArea {
        id
        title
        wrongAns
        video
      }
    }
  }
`;

const IndividualReportGQL: any = gql`
  query individualReport($msoID: ID!) {
    individualReport(msoID: $msoID) {
      testDropped
      testOngoing
      testCompleted
      lastTestModule {
        id
        title
        lastScore
        lastTestDate
      }
      testCompleted
      moduleProblem {
        id
        title
        lastScore
      }
      subjectReport {
        title
        report {
          testTaken
          TestAvgScore
          testDropped
          lastTestModule {
            id
            title
            lastTestDate
            lastScore
          }
          problemArea {
            id
            title
            wrongAns
            video
          }
        }
      }
    }
  }
`;

export { MSOListGQL, ModuleListGQL, MsoModuleReportGQL, IndividualReportGQL };
