import { TestQuesCheckBoxType } from "../../../models/TestTypes";
const CheckBoxOpt = (data: TestQuesCheckBoxType): JSX.Element => {
  return (
    <div className="custom-control  mb-3">
      <input
        className="custom-control-input"
        value={data.value}
        checked={
          data.ids.length > 0 &&
          data.ids.findIndex((item) => item.id === data.id) !== -1
            ? true
            : false
        }
        name="msoInput"
        onChange={data.onChange}
        id={data.id}
        type="checkbox"
      />
      <label
        className="custom-control-label"
        style={{ cursor: "pointer" }}
        htmlFor={data.id}
      >
        {data.label}
      </label>
    </div>
  );
};
export default CheckBoxOpt;
