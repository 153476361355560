import { Card, CardBody, Form, Row, Col } from "reactstrap";
import RadioButton from "../../../pages/mso/ModuleTest/RadioButton";
import CheckBoxOpt from "../../../pages/mso/ModuleTest/CheckBox";
import TextBox from "../../../pages/mso/ModuleTest/TextBox";
import { DocumentRenderer } from "@keystone-6/document-renderer";
import { quesType } from "../../../utils";
import TestAction from "../../../pages/mso/ModuleTest/TestAction";
import LanguageSelector from "../../../components/DropDown/LanguageSelector";
import { TestQuesViewTypes } from "../../../models/TestTypes";
const QuesView = (quesData: TestQuesViewTypes): JSX.Element => {
  const preDisabled = quesData.QuesIndex === 0 ? true : false;
  const nextDisabled =
    quesData.QuesIndex > quesData.quesLength - 2 ? true : false;
  const quesDetails = quesData.ques.question;
  const option = quesDetails.Queschoice;

  return (
    <>
      <Card>
        <CardBody md="12" xl="12" style={{ maxHeight: "600px" }}>
          <Col md="12" xl="12">
            <Row md="12">
              <table width="100%">
                <tbody>
                  <tr>
                    <td align="right">
                      <LanguageSelector />
                    </td>
                    <td align="right">
                      <TestAction testUpdate={quesData.testUpdate} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </Row>
          </Col>
          <Row>
            <Col md="12">
              <div className="col pl-1">
                <span className="custHeading"> Subject </span>
                <b>:-</b> <b>{quesData.moduleName}</b>
              </div>
            </Col>
          </Row>
          <div>
            <b>
              Q.-{quesData.QuesIndex + 1} {quesType(quesDetails["type"])}
            </b>
          </div>

          <div>
            <DocumentRenderer document={quesDetails["statement"].document} />
          </div>

          <Form style={{ height: "200px" }}>
            {quesDetails.type === "single_correct"
              ? option.map((data, index: number) => (
                  <RadioButton
                    key={index}
                    id={data.id}
                    label={data["statement"]}
                    onChange={quesData.radioHandler}
                    ids={quesData.answer}
                  />
                ))
              : ""}

            {quesDetails.type === "multiple_correct"
              ? option.map((data, index: number) => (
                  <CheckBoxOpt
                    key={index}
                    value={data.id}
                    id={data.id}
                    label={data["statement"]}
                    onChange={quesData.multiChoice}
                    ids={quesData.answer}
                  />
                ))
              : ""}
            {quesDetails.type === "open_text" ? (
              <TextBox
                inputHandler={quesData.inputHandler}
                inputOnchange={quesData.inputOnchange}
                answer={quesData.answer}
              />
            ) : (
              ""
            )}
          </Form>

          <Row md="12" xl="12" style={{ textAlign: "center" }}>
            <Col md="4" xl="4" className="mx-width">
              <button
                className={`${preDisabled ? "btnDis" : "testBtn next"}`}
                onClick={(e) => quesData.action(e, quesData.QuesIndex - 1)}
                disabled={preDisabled}
              >
                Prev
              </button>
            </Col>
            <Col md="3" xl="3" className="mx-width">
              <button
                className="testBtn"
                disabled={quesData.answer.length === 0 ? true : false}
                onClick={(e) => quesData.clearAns(e)}
              >
                Clear
              </button>
            </Col>
            <Col md="5" xl="5" className="mx-width">
              {!nextDisabled || quesData.answer.length === 0 ? (
                <button
                  className={`${nextDisabled ? "btnDis" : "testBtn next"}`}
                  onClick={(e) => quesData.action(e, quesData.QuesIndex + 1)}
                  disabled={nextDisabled}
                >
                  Next
                </button>
              ) : (
                <button
                  className="btn btn-success"
                  onClick={(e) => quesData.testUpdate(e, "Completed")}
                >
                  Complete Test
                </button>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default QuesView;
