import { Card, CardBody, Container, Row, Col } from "reactstrap";

import { useLazyQuery } from "@apollo/client";
import Loading from "../../../components/Loading";
import CardsHeader from "../../../components/Header/CardsHeader";
import { useEffect, useReducer, useState } from "react";
import { MSOListGQL, ModuleListGQL } from "../../../graphql/report";
import IndividualReport from "./IndividualReport";
import MSOModuleReport from "./MSOModuleReport";
import {
  msoReportReducer,
  initialState,
} from "../../../reducer/MSOReportReducer";
import "./style.css";

const MSOReport = (): JSX.Element => {
  const [state, dispatch] = useReducer(msoReportReducer, initialState);
  const [MSOList]: any = useLazyQuery(MSOListGQL);
  const [ModuleList]: any = useLazyQuery(ModuleListGQL);

  const [selectedMSO, setMSO] = useState("");
  const [selectedModule, setModule] = useState("");

  useEffect(() => {
    async function fetchMyAPI(type: string) {
      if (type === "msoList") {
        const res = await MSOList({
          variables: {
            where: { type: { in: ["mso"] }, activestatus: { equals: true } },
            take: 500,
            skip: 0,
            orderBy: [{ name: "asc" }],
          },
        });
        if (!res.loading) {
          dispatch({ type: "setMSOList", payload: res.data.items });
          dispatch({ type: "setLoader", payload: false });
        } else {
          dispatch({ type: "setLoader", payload: true });
        }
      } else {
        const res = await ModuleList({
          variables: {
            where: { active: { equals: true } },
            take: 500,
            skip: 0,
            orderBy: [{ title: "asc" }],
          },
        });
        if (!res.loading) {
          dispatch({ type: "setModuleList", payload: res.data.items });
          dispatch({ type: "setLoader", payload: false });
        } else {
          dispatch({ type: "setLoader", payload: true });
        }
      }
    }

    fetchMyAPI("msoList");
    fetchMyAPI("moduleList");
  }, [MSOList, ModuleList]);
  const SetCurrentMSO = (index: string) => setMSO(index);
  const SetCurrentModule = (index: string) => setModule(index);
  return (
    <>
      <CardsHeader name="Module Report" parentName="MSO Report" />
      <Container className="mt-2 pl-0 pr-0" fluid>
        <Col className="card-wrapper pl-0 pr-0" xl="12">
          <Card>
            <CardBody md="12" xl="12" className="p-0">
              {!state.loader ? (
                <Row xl="12">
                  <IndividualReport
                    msoList={state.msoList}
                    currentMSO={SetCurrentMSO}
                    currentModule={SetCurrentModule}
                  />
                  {selectedMSO !== "" ? (
                    <MSOModuleReport
                      selectedMSO={selectedMSO}
                      selectedModule={selectedModule}
                      moduleList={state.moduleList}
                      currentModule={SetCurrentModule}
                    />
                  ) : (
                    ""
                  )}
                </Row>
              ) : (
                <Loading />
              )}
            </CardBody>
          </Card>
        </Col>
      </Container>
    </>
  );
};

export default MSOReport;
