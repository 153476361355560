import ReactPlayer from "react-player";
const URLVideoPlayer = (propsData: { url: string }): JSX.Element => {
  let videoUrl: string = "";
  if (propsData.url.includes("youtube.com")) {
    videoUrl = propsData.url;
  }

  return (
    <>
      {propsData.url !== "" &&
      propsData.url !== null &&
      propsData.url !== undefined ? (
        <div className="p-2 pb-4">
          {videoUrl !== "" ? (
            <div>
              <ReactPlayer
                width="100%"
                height="250px"
                url={videoUrl}
                controls={true}
              />
            </div>
          ) : (
            <a target="_blank" rel="noreferrer" href={propsData.url}>
              External Link
            </a>
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default URLVideoPlayer;
