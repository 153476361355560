import { Card, CardHeader, Container, Row, Col, CardBody } from "reactstrap";
import { istDateTime } from "../../../utils";
import { useHistory, useParams } from "react-router";
import { TestQgl } from "../../../graphql/Test";
import { useLazyQuery } from "@apollo/client";
import Loading from "../../../components/Loading";
import CardsHeader from "../../../components/Header/CardsHeader";
import { useEffect, useReducer } from "react";
import TableList from "../../../components/Table/DataTable";
import Back from "../../../components/Back/back";
import ReviewTestColumns from "../../../pages/TableColumn/reviewer/TestReview";
import { getListDetail } from "../../../utils";
import {
  testReportReducer,
  initialState,
} from "../../../reducer/testReportReducer";
const ReviewTestList = (): JSX.Element => {
  const [state, dispatch] = useReducer(testReportReducer, initialState);
  const params: any = useParams();
  const selectedList = getListDetail(params.type);
  const { push } = useHistory();
  const [loadCompletedTest]: any = useLazyQuery(TestQgl);

  const noOfDataPerPage = 10;

  const backAction = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    push("/mso/dashboard");
  };
  const viewQues = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    tid: string
  ) => {
    e.preventDefault();
    push("/reviewer/TestQuestions/" + tid + "/" + selectedList.mode);
  };

  const fetchData = async (Page: number) => {
    dispatch({ type: "setLoader", payload: true });
    const res = await loadCompletedTest({
      variables: {
        orderBy: selectedList.orderBy,
        where: selectedList.where,
        take: noOfDataPerPage,
        skip: Page * noOfDataPerPage,
      },
    });
    if (!res.loading) {
      const dataList: any = [];
      if (res.data.items.length > 0) {
        dispatch({ type: "setDataLength", payload: res.data.count });
        for (const data of res.data.items) {
          dataList.push({
            id: data.id,
            test_no: data.test_no,
            module: data.module_id.label,
            appeared_by: data.appeared_by.label,
            finalisedAt: istDateTime(data.finalisedAt),
            reviewedAt: istDateTime(data.reviewedAt),
            obtainedMarks: data.obtainedMarks,
            reviewer_status: data.reviewer_status,
            view: (
              <a
                href="#/"
                title="View"
                style={{ cursor: "pointer" }}
                onClick={(e) => viewQues(e, data.id)}
              >
                View
              </a>
            ),
          });
        }

        dispatch({ type: "setCurrentPage", payload: Page });
        dispatch({ type: "setTestList", payload: dataList });
      }
      dispatch({ type: "setLoader", payload: false });
    }
  };

  useEffect(() => {
    fetchData(0);
  }, []);

  return (
    <>
      <CardsHeader name="MSO Traning" parentName="Completed Test" />
      {!state.loader ? (
        <Container className="mt-2" fluid>
          <Col className="card-wrapper" xl="12">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">{selectedList.info}</h3>
                  </Col>
                  <Col className="text-right" xs="6">
                    <Back action={backAction} />
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="te">
                {state.testList !== "" && state.dataLength > 0 ? (
                  <TableList
                    TableData={state.testList}
                    Columns={ReviewTestColumns(params.type)}
                    page={state.currentPage}
                    fetchData={fetchData}
                    dataLength={state.dataLength}
                    noOfDataPerPage={noOfDataPerPage}
                  />
                ) : (
                  <Row className="justify-content-md-center">
                    <h4> Empty test list </h4>
                  </Row>
                )}
              </CardBody>
            </Card>
          </Col>
        </Container>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default ReviewTestList;
