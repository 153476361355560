export function msoReportReducer(state: any, action: any) {
  switch (action.type) {
    case "setLoader": {
      return {
        ...state,
        error: "",
        loader: action.payload,
      };
    }
    case "setReportLoader": {
      return {
        ...state,
        error: "",
        reportLoader: action.payload,
      };
    }

    case "setModuleReport": {
      return {
        ...state,
        error: "",
        moduleReport: action.payload,
      };
    }
    case "setMSOReport": {
      return {
        ...state,
        error: "",
        msoReport: action.payload,
      };
    }
    case "setMSOList": {
      return {
        ...state,
        error: "",
        msoList: action.payload,
      };
    }
    case "setSelectedMSO": {
      return {
        ...state,
        error: "",
        selectedMSO: action.payload,
      };
    }

    case "setModuleList": {
      return {
        ...state,
        error: "",
        moduleList: action.payload,
      };
    }

    default:
      return state;
  }
}

export const initialState = {
  loader: true,
  msoList: "",
  moduleList: "",
  selectedMSO: "",
  reportLoader: "",
  moduleReport: "",
  msoReport: "",
};
