import React, { useReducer } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Col, Row } from "reactstrap";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { PDFReducer, initialState } from "../../reducer/PDFReducer";
import "./index.css";

interface optionsTypes {
  cMapUrl: string;
  cMapPacked: boolean;
  standardFontDataUrl: string;
}
const options: optionsTypes = {
  cMapUrl: "cmaps/",
  cMapPacked: true,
  standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,
};

const PDFVIEW = (data: { file: string }): JSX.Element => {
  const [state, dispatch] = useReducer(PDFReducer, initialState);

  function onDocumentLoadSuccess({
    numPages: nextNumPages,
  }: {
    numPages: number;
  }) {
    dispatch({ type: "setNumPages", payload: nextNumPages });
  }
  const DownloadHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    window.open(data.file, "PRINT", "height=400,width=600");
  };

  return (
    <>
      <div className="ReactPdfViewer">
        <Col xl="12" md="12">
          <Row xl="12" md="12">
            <header className="pdf_header"> 
              <table style={{width:"100%"}}>
                <tbody>
                  <tr>
                    <td>              <i
                onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                  dispatch({
                    type: "setPageNo",
                    payload: state.pageNo > 1 ? state.pageNo - 1 : 1,
                  })
                }
                className="fas fa-angle-double-left"
                aria-hidden="true"
              ></i>
</td>
<td> 
              <i
                onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                  dispatch({ type: "setScale", payload: state.scale + 0.1 })
                }
                className="fa fa-plus"
                aria-hidden="true"
              ></i>
</td>
<td> 
              {state.numPages > 0 ? (
                <select
                  className="select"
                  value={state.pageNo - 1}
                  onChange={(e: React.FormEvent<HTMLSelectElement>) =>
                    dispatch({
                      type: "setPageNo",
                      payload: parseInt(e.currentTarget.value) + 1,
                    })
                  }
                >
                  {Array.from(
                    new Array(state.numPages),
                    (el: any, index: number) => (
                      <option key={index} value={index}>
                        {" "}
                        {index + 1}{" "}
                      </option>
                    )
                  )}
                </select>
              ) : (
                ""
              )}
</td>
<td> 
              <i
                onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                  dispatch({
                    type: "setScale",
                    payload: state.scale > 0.25 ? state.scale - 0.1 : 0.2,
                  })
                }
                className="fa fa-minus"
                aria-hidden="true"
              ></i>
</td>
<td> 
              <i
                onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                  dispatch({
                    type: "setPageNo",
                    payload:
                      state.pageNo < state.numPages
                        ? state.pageNo + 1
                        : state.numPages,
                  })
                }
                className="fas fa-angle-double-right"
              ></i>
</td>
<td> 
              <i
                onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                  DownloadHandler(e)
                }
                className="fa fa-print"
                aria-hidden="true"
              ></i>
</td>
<td> 
              <i
                onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                  DownloadHandler(e)
                }
                className="fa fa-download"
                aria-hidden="true"
              ></i>
              </td>
              </tr>
              </tbody>
              </table>
            </header>
          </Row>
          <Row xl="12" md="12" style={{ overflow: "auto", maxHeight: "400px" }}>
            <div className="PDF__container">
              <div className="PDF__container__document">
                <Document
                  file={data.file}
                  onLoadSuccess={onDocumentLoadSuccess}
                  options={options}
                >
                  <Page scale={state.scale} pageNumber={state.pageNo}>
                    <div className="pdf_footer">
                      Page {state.pageNo} of {state.numPages}
                    </div>
                  </Page>
                </Document>

                {state.numPages === 0 ? <b>Page is Loading</b> : ""}
              </div>
            </div>
          </Row>
        </Col>
      </div>
    </>
  );
};

export default PDFVIEW;
