export function testReducer(state: any, action: any) {
  switch (action.type) {
    case "setLoader": {
      return {
        ...state,
        error: "",
        loader: action.payload,
      };
    }

    case "setCurrQues": {
      return {
        ...state,
        error: "",
        currentQues: action.payload,
      };
    }

    case "setQuesList": {
      return {
        ...state,
        error: "",
        quesList: action.payload,
      };
    }

    case "setModule": {
      return {
        ...state,
        error: "",
        moduleName: action.payload,
      };
    }

    case "setList": {
      return {
        ...state,
        error: "",
        list: action.payload,
      };
    }

    case "setQIndex": {
      return {
        ...state,
        error: "",
        quesIndex: action.payload,
      };
    }

    case "SetAnswer": {
      return {
        ...state,
        error: "",
        answer: action.payload,
      };
    }

    default:
      return state;
  }
}

export const initialState = {
  loader: true,
  currentQues: "",
  quesList: "",
  moduleName: "",
  quesIndex: 0,
  answer: "",
  list: "",
  error: "",
};
