export function topicReducer(state: any, action: any) {
  switch (action.type) {
    case "setTimer": {
      return {
        ...state,
        error: "",
        timer: action.payload,
      };
    }
    case "setTopicList": {
      return {
        ...state,
        error: "",
        topicList: action.payload,
      };
    }
    case "setTarget": {
      return {
        ...state,
        error: "",
        target: action.payload,
      };
    }
    case "setVideoUrl": {
      return {
        ...state,
        error: "",
        videoUrl: action.payload,
      };
    }

    case "setVListIndx": {
      return {
        ...state,
        error: "",
        vListIndx: action.payload,
      };
    }
    case "setLinkTitle": {
      return {
        ...state,
        error: "",
        linkTitle: action.payload,
      };
    }
    case "setOtherLink": {
      return {
        ...state,
        error: "",
        otherLink: action.payload,
      };
    }
    case "setInterverID": {
      return {
        ...state,
        error: "",
        intervalID: action.payload,
      };
    }
    case "setReqStatus": {
      return {
        ...state,
        error: "",
        reqStatus: action.payload,
      };
    }

    case "setSubject": {
      return {
        ...state,
        error: "",
        selectedSubjected: action.payload,
      };
    }
    case "setLoader": {
      return {
        ...state,
        error: "",
        loader: action.payload,
      };
    }
    case "setShow": {
      return {
        ...state,
        error: "",
        show: action.payload,
      };
    }
    default:
      return state;
  }
}

export const initialState = {
  loader: true,
  videoUrl: "unload",
  vListIndx: "",
  linkTitle: "",
  intervalID: "",
  reqStatus: "Get Support",
  selectedSubjected: "",
  otherLink: "",
  topicList: "",
  timer: 0,
  show: false,
  target: "",
  error: "",
};
