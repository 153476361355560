export function TableListReducer(state: any, action: any) {
  switch (action.type) {
    case "setLoader": {
      return {
        ...state,
        error: "",
        loader: action.payload,
      };
    }
    case "setCurrentPage": {
      return {
        ...state,
        error: "",
        currentPage: action.payload,
      };
    }

    case "setTableList": {
      return {
        ...state,
        error: "",
        listData: action.payload,
      };
    }

    case "setTableLength": {
      return {
        ...state,
        error: "",
        tableLength: action.payload,
      };
    }

    default:
      return state;
  }
}

export const ListInitialState = {
  loader: true,
  currentPage: "",
  listData: "",
  tableLength: 0,
  error: "",
};
