export default function ReviewTestColumns(type: string): any {
  const column = [
    {
      name: "Subject",
      selector: "module",
    },
    {
      name: "TEST NO.",
      selector: "test_no",
    },
    {
      name: "APPEARED BY",
      selector: "appeared_by",
    },
    {
      name: type === "Pending" ? "COMPLETED AT" : "Finalised At",
      selector: type === "Pending" ? "finalisedAt" : "reviewedAt",
    },
    {
      name: "SCORRED",
      selector: "obtainedMarks",
    },
    {
      name: "REVIEWER STATUS",
      selector: "reviewer_status",
    },
    {
      name: "",
      selector: "view",
    },
  ];
  return column;
}
