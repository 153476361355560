import "./style.scss";
import { Route, Switch } from "react-router-dom";
import { useState, useRef, useContext } from "react";
import RouteData from "./RouteData";
import Header from "../Header";
import NavLeft from "../../components/NavLeft";
import SingUp from "../../pages/Login/SingUp";
import { LogIn, LogOut } from "../../pages/Login";
import { LoginContext } from "../../context/loginContext";
import { RouteListType, RoutesDetailType } from "../../models/RoutesTypes";

function Layout(): JSX.Element {
  const ctx = useContext(LoginContext);
  const routes: RouteListType = RouteData[ctx.role];
  const [sidenavOpen, setSidenavOpen] = useState(true);

  const mainContentRef = useRef<HTMLInputElement>(null);

  const getRoutes = (routes: any) => {
    return routes.map((prop: RoutesDetailType, key: number) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }

      if (prop.layout === ctx.role) {
        return <Route path={prop.path} component={prop.component} key={key} />;
      } else {
        //return <Route component={unAuthorised} />;
        return null;
      }
    });
  };

  const toggleSidenav = (e: any) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    setSidenavOpen(!sidenavOpen);
  };

  return (
    <>
      {ctx.role !== "" ? (
        <NavLeft
          routes={routes}
          toggleSidenav={toggleSidenav}
          sidenavOpen={sidenavOpen}
          logo={{
            innerLink: "/",
            imgSrc: require("../../assets/img/brand/logo.jpg").default,
            imgAlt: "...",
          }}
        />
      ) : (
        ""
      )}

      <div className="main-content" ref={mainContentRef}>
        {ctx.role !== "" ? (
          <Header
            theme="dark"
            toggleSidenav={toggleSidenav}
            sidenavOpen={sidenavOpen}
            name={ctx.userName}
            role={ctx.role}
          />
        ) : (
          ""
        )}

        <Switch>
          {ctx.role !== "" ? (
            getRoutes(routes)
          ) : (
            <>
              <Route path="/login" component={LogIn} exact />
              <Route path="/SingUp" component={SingUp} exact />
            </>
          )}
          {ctx.role !== "" ? (
            <Route path="/logout" component={LogOut} exact />
          ) : (
            ""
          )}
        </Switch>
      </div>

      {sidenavOpen ? (
        <div className="backdrop d-xl-none" onClick={toggleSidenav} />
      ) : (
        ""
      )}
    </>
  );
}

export default Layout;
