import {
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
} from "reactstrap";
import { useState } from "react";
import classnames from "classnames";
import { Countdown } from "../../components/timer/CountDown";
import { OTPFormTypes } from "../../models/LoginTypes";
const OTPForm = (data: OTPFormTypes): JSX.Element => {
  const [OTP, setOTP] = useState(false);
  return (
    <>
      <Form>
        <div style={{ textAlign: "center", width: "100%" }}>
          <h4 className="otpMsg">OTP is sent on {data.mobileNo}</h4>
          <div className="mb-3" style={{ textAlign: "center", width: "100%" }}>
            <Col md="12">
              <FormGroup>
                <InputGroup
                  className={classnames("input-group-merge", {
                    focused: OTP,
                  })}
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-unlock-alt" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    className="input"
                    placeholder="Enter OTP"
                    type="text"
                    onChange={data.onChange}
                    onFocus={(e) => setOTP(true)}
                    onBlur={(e) => setOTP(false)}
                    value={data.OTP}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </div>
          <div className="row">
            <div className="col-xl-3" style={{ maxWidth: "30%" }}>
              <button
                className="btn btn-secondary"
                type="button"
                disabled={data.OTP.trim() === ""}
                onClick={data.clearOtp}
                style={{ padding: "5px" }}
              >
                Clear
              </button>
            </div>
            <div className="col-xl-5" style={{ maxWidth: "40%" }}>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={data.OTP.length < 6}
                onClick={(e) => data.OTPValidate(e)}
                style={{ padding: "5px" }}
              >
                Validate OTP
              </button>
            </div>
            <div className="col-xl-3" style={{ maxWidth: "30%" }}>
              {data.watch && <Countdown seconds={10} action={data.resendOTP} />}
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

export default OTPForm;
