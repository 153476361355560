import { Card, CardHeader, CardBody, Container, Row, Col } from "reactstrap";
import { useHistory } from "react-router";
import { TestQglCount } from "../../graphql/Test";
import { useLazyQuery } from "@apollo/client";
import Loading from "../../components/Loading";
import CardsHeader from "../../components/Header/CardsHeader";
import { useEffect, useState } from "react";
import { getCurrentDate } from "../../utils";
import CustomCard from "../../components/Card/Card";

const MSODashboard = (): JSX.Element => {
  const { push } = useHistory();
  const [todayFinalised]: any = useLazyQuery(TestQglCount);
  const [loader, SetLoader]: any = useState(true);
  const [todayCount, SettodayCount]: any = useState();
  const [totalCount, SettotalCount]: any = useState();
  const [pendingCount, SetpendingCount]: any = useState();
  const handleClick = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    type: String
  ) => {
    push("/reviewer/ReviewTestList/" + type);
  };
  useEffect(() => {
    async function fetchMyAPI() {
      const res = await todayFinalised({
        variables: {
          where: {
            reviewedAt: {
              gte: new Date(getCurrentDate("-", 0)),
              lt: new Date(getCurrentDate("-", 1)),
            },
            reviewer_status: { equals: "Finalized" },
          },
        },
      });
      SettodayCount(res.data.count);

      const totalDone = await todayFinalised({
        variables: {
          where: {
            reviewer_status: { equals: "Finalized" },
          },
        },
      });
      SettotalCount(totalDone.data.count);

      const pending = await todayFinalised({
        variables: {
          where: {
            test_status: { equals: "Completed" },
            reviewer_status: { equals: "Pending" },
          },
        },
      });
      SetpendingCount(pending.data.count);
      !pending.loading ? SetLoader(false) : SetLoader(true);
    }
    fetchMyAPI();
  }, [todayFinalised]);

  return (
    <>
      <CardsHeader name="Reviewer" parentName="Dashboard" />
      <Container className="mt-2 pl-0 pr-0" fluid>
        <Col className="card-wrapper pl-0 pr-0" xl="12">
          <Card>
            <CardHeader className="bg-transparent">
              <h6 className="text-muted text-uppercase ls-1 mb-1">Dashboard</h6>
            </CardHeader>
            <CardBody md="12" xl="12">
              {!loader ? (
                <Row xl="12">
                  <CustomCard
                    handleClick={handleClick}
                    Count={todayCount}
                    status="Today"
                    info="Today Reviewed"
                  />
                  <CustomCard
                    handleClick={handleClick}
                    Count={totalCount}
                    status="Total"
                    info="Total Review"
                  />
                  <CustomCard
                    handleClick={handleClick}
                    Count={pendingCount}
                    status="Pending"
                    info="Pending Reviewed"
                  />
                </Row>
              ) : (
                <Loading />
              )}
            </CardBody>
          </Card>
        </Col>
      </Container>
    </>
  );
};

export default MSODashboard;
