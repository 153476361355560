import { useState, useEffect } from "react";

interface countDownData {
  seconds: number;
  action: any;
}

const useCountDown = (start: number) => {
  const [counter, setCounter] = useState(start);
  useEffect(() => {
    if (counter >= 0) {
      const timer = setTimeout(() => {
        setCounter(counter - 1);
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [counter]);

  if (counter >= 0) {
    return counter;
  } else {
    return 0;
  }
};

export const Countdown = ({ seconds, action }: countDownData): JSX.Element => {
  const timeLeft = useCountDown(seconds);
  if (timeLeft > 0) {
    return <div> Resend OTP in {timeLeft}s</div>;
  } else {
    return (
      <div>
        <button
          type="button"
          className="btn btn-default"
          style={{ padding: "5px" }}
          onClick={(e) => action(e)}
        >
          Resend
        </button>
      </div>
    );
  }
};
