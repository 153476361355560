import { Card, CardHeader, Container, Row, Table, Col } from "reactstrap";
import { useHistory, useParams } from "react-router";
import { TestQgl } from "../../../graphql/Test";
import { useLazyQuery } from "@apollo/client";
import Loading from "../../../components/Loading";
import CardsHeader from "../../../components/Header/CardsHeader";
import { useEffect, useState, useContext } from "react";
import { istDateTime } from "../../../utils";
import Back from "../../../components/Back/back";
import { LoginContext } from "../../../context/loginContext";
import { TestListTypes } from "../../../models/ListTypes";
const CompletedTest = (): JSX.Element => {
  const cnxt = useContext(LoginContext);
  const params: { id: string } = useParams();
  const { push } = useHistory();
  const [testList, setTestList] = useState<TestListTypes[]>([]);
  const [loadCompletedTest] = useLazyQuery(TestQgl);
  const [loader, SetLoader] = useState(true);
  const [selectedModule, setModule] = useState("");
  const backAction = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    push("/mso/dashboard");
  };
  const viewQues = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    tid: string
  ) => {
    e.preventDefault();
    push("/mso/TestQuestions/" + tid + "/view");
  };
  useEffect(() => {
    async function fetchMyAPI() {
      const res = await loadCompletedTest({
        variables: {
          orderBy: [{ createdAt: "desc" }],
          where: {
            appeared_by: {
              id: {
                in: [cnxt.userId],
              },
            },
            module_id: {
              id: {
                in: [params.id],
              },
            },
            test_status: {
              in: ["Completed"],
            },
          },
          take: 50,
          skip: 0,
        },
      });
      if (!res.loading) {
        if (res.data.items.length > 0) {
          setTestList(res.data.items);
          setModule(res.data.items[0].module_id.label);
        }

        SetLoader(false);
      }
    }
    fetchMyAPI();
  }, [cnxt.userId, params.id, loadCompletedTest]);

  return (
    <>
      <CardsHeader name="MSO Traning" parentName="Completed Test" />
      {!loader ? (
        <Container className="mt-2 pl-0 pr-0" fluid>
          <Col className="card-wrapper pl-0 pr-0" xl="12">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">
                      {" "}
                      Completed test list of {selectedModule}
                    </h3>
                  </Col>
                  <Col className="text-right" xs="6">
                    <Back action={backAction} />
                  </Col>
                </Row>
              </CardHeader>

              <Table
                className="align-items-center table-flush"
                responsive
                striped
              >
                <thead className="thead-light">
                  <tr>
                    <th>Test No.</th>
                    <th>Started at</th>
                    <th>Completed At</th>
                    <th>Attempted Ques.</th>
                    <th>Scorred</th>
                    <th>Reviewer Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {testList.map((data, index: number) => (
                    <tr key={data.id}>
                      <td>
                        <span className="text-muted">{data.test_no}</span>
                      </td>
                      <td>
                        <span className="text-muted">
                          <a
                          href="#/"
                          style={{ cursor: "pointer" }}
                          title="View Question"
                          onClick={(e) => viewQues(e, data.id)}
                        >
                          {istDateTime(data.createdAt)}
                          </a>
                        </span>
                      </td>
                      <td>{istDateTime(data.finalisedAt)}</td>
                      <td className="table-actions">
                        {data.attemptedQuestion}
                      </td>
                      <td>{data.obtainedMarks}</td>
                      <td>{data.reviewer_status}</td>
                      <td>
                        <a
                          href="#/"
                          style={{ cursor: "pointer" }}
                          title="View Question"
                          onClick={(e) => viewQues(e, data.id)}
                        >
                          View
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Container>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default CompletedTest;
