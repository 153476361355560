import { gql } from "@apollo/client";
const GetOtpGql: any = gql`
  mutation GetOtp($mobile: String!, $ReqType: String!) {
    GetOTP(mobile: $mobile, ReqType: $ReqType) {
      message
      code
    }
  }
`;

const singUpGql: any = gql`
  mutation SingUP(
    $mobile: String!
    $email: String!
    $name: String!
    $pincode: Int!
    $otp: Int!
  ) {
    SingUP(
      mobile: $mobile
      email: $email
      name: $name
      pincode: $pincode
      otp: $otp
    ) {
      message
      code
    }
  }
`;

const ValidateOtpGql: any = gql`
  mutation ValidateOtp($mobile: String!, $otp: Int!) {
    ValidateOtp(mobile: $mobile, otp: $otp) {
      mobile
      code
      message
      userId
      type
      userName
      preferlanguage
    }
  }
`;

const IsLoggedInGql: any = gql`
  query UserIsLoggedIn {
    UserIsLoggedIn {
      userId
      role
      name
      message
      preferlanguage
    }
  }
`;

const LogOutGql: any = gql`
  query LogOut {
    LogOut {
      message
    }
  }
`;

const ChangeLanguageGql: any = gql`
  mutation ($data: userUpdateInput!, $id: ID!) {
    item: updateuser(where: { id: $id }, data: $data) {
      id
    }
  }
`;

export {
  GetOtpGql,
  ValidateOtpGql,
  IsLoggedInGql,
  LogOutGql,
  singUpGql,
  ChangeLanguageGql,
};
