import { useContext } from "react";
import { Col } from "reactstrap";
import { useMutation } from "@apollo/client";
import { LoginContext } from "../../context/loginContext";
import { ChangeLanguageGql } from "../../graphql/login";
import Loading from "../../components/Loading";

const LanguageSelector = (): JSX.Element => {
  const { onLogin }: any = useContext(LoginContext);
  const ctx = useContext(LoginContext);
  const [saveLanguage, { loading }] = useMutation(ChangeLanguageGql);
  const changeLanguage = async (e: any) => {
    e.preventDefault();
    const selectedLanguage = e.currentTarget.value;
    const res = await saveLanguage({
      variables: {
        data: { preferlanguage: selectedLanguage },
        id: ctx.userId,
      },
    });
    if (!loading && res.data.id !== "") {
      onLogin(ctx.userName, ctx.userId, ctx.role, selectedLanguage);
    }
  };
  return (
    <>
      {!loading ? (
        <Col className="col-auto">
          <label className="mb-0">Language </label>
          <select
            value={ctx.language}
            onChange={(e) => changeLanguage(e)}
            className="ml-1"
          >
            <option value="Hindi">Hindi</option>
            <option value="English">English</option>
          </select>
        </Col>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default LanguageSelector;
