export const gql = ([content]: TemplateStringsArray) => content;

export async function fetchGraphQL(
  query: string,
  variables?: Record<string, any>
) {
  return fetch("http://localhost:8000/api/graphql", {
    method: "POST",
    body: JSON.stringify({ query, variables }),
    headers: { "Content-Type": "application/json" },
  })
    .then((x) => x.json())
    .then(({ data, errors }) => {
      if (errors) {
        throw new Error(
          `GraphQL errors occurred:\n${errors
            .map((x: any) => x.message)
            .join("\n")}`
        );
      }
      return data;
    });
}

export function quesType(type: string): String {
  let quesType = "";
  if (type === "open_text") {
    quesType = "Free Text";
  } else if (type === "multiple_correct") {
    quesType = "Multi Choice";
  } else {
    quesType = "Single Choice";
  }
  return quesType;
}

export function istDateTime(dateTime: string): String {
  const newDateTime = new Date(dateTime);
  const saveConverted = newDateTime.toLocaleString();
  return saveConverted;
}

type SelectedOption = {
  id: String;
  correct: boolean;
};

export function checkOption(id: string, options: SelectedOption[]): any {
  let retType = "";

  for (const option of options) {
    if (option.id === id && option.correct === true) {
      retType = "right";
      break;
    } else if (option.id === id && option.correct === false) {
      retType = "wrong";
      break;
    }
  }

  if (retType === "") {
    retType = "not_selected";
  }

  return retType;
}

export function getCurrentDate(separator: any = "", isNext: any = 0): any {
  let newDate = new Date();
  if (isNext > 0) {
    newDate.setDate(newDate.getDate() + 1);
  }
  let date_raw = newDate.getDate();
  let month_raw = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  var date, month;

  if (date_raw < 10) {
    date = "0" + date_raw.toString();
  } else {
    date = date_raw.toString();
  }
  if (month_raw < 10) {
    month = "0" + month_raw.toString();
  } else {
    month = month_raw.toString();
  }

  return year + "" + separator + month + separator + date;
}
export function getListDetail(type: string): any {
  const list: any = {
    Today: {
      mode: "review",
      info: "Today Reviewed Test List",
      where: {
        reviewedAt: {
          gte: new Date(getCurrentDate("-", 0)),
          lt: new Date(getCurrentDate("-", 1)),
        },
        reviewer_status: { equals: "Finalized" },
      },
      orderBy: [{ reviewedAt: "desc" }],
    },
    Total: {
      mode: "view",
      info: "Total Reviewed Test List",
      where: {
        reviewer_status: { equals: "Finalized" },
      },
      orderBy: [{ reviewedAt: "desc" }],
    },
    Pending: {
      mode: "review",
      info: "Pending Review Test List",
      where: {
        test_status: { equals: "Completed" },
        reviewer_status: { equals: "Pending" },
      },
      orderBy: [{ finalisedAt: "asc" }],
    },
  };
  return list[type];
}

export function youtubeCode(code: String): String {
  if (code.includes("v=")) {
    return code.split("v=")[1].split("&")[0];
  } else {
    return "";
  }
}
export function arrayValueExist(arr: string[], value: string) {
  return arr.some(function (el: any) {
    return el === value;
  });
}
export function Allowextennstion(type: string) {
  const arr: any = {
    video: [
      "mp4",
      "wav",
      "mp3",
      "mov",
      "wmv",
      "flv",
      "avi",
      "avchd",
      "webm",
      "mkv",
    ],
    image: ["jpeg", "jpg", "png", "gif", "PSD"],
    pdf: ["pdf"],
  };
  return arr[type];
}
