import { Card, CardBody, Row, Col } from "reactstrap";

import { useEffect, useContext, useReducer } from "react";
import YouTube from "react-youtube";
import { useHistory, useParams } from "react-router";
import { useMutation } from "@apollo/client";
import { VideoSupportGql } from "../../graphql/Video";
import VideoList from "./VideoList";
import Loading from "../../components/Loading";
import { LoginContext } from "../../context/loginContext";
import { topicReducer, initialState } from "../../reducer/topicReducer";
import ExternalLink from "./ExternalLink";
import { TopicDetailTypes, selectTopicTypes } from "../../models/TopicTypes";
import TopicQuery from "../../components/video/TopicQuery";
interface propsTypes {
  data: TopicDetailTypes[];
  vListIndx: selectTopicTypes;
}

const VideoPlayer = (propsData: propsTypes): JSX.Element => {
  const data = propsData.data;
  const [state, dispatch] = useReducer(topicReducer, initialState);
  const ctx = useContext(LoginContext);
  const params: any = useParams();
  const { push } = useHistory();

  const handleShow = () => {
    dispatch({ type: "setShow", payload: true });
    if (state.target !== "" && state.target !== undefined) {
      state.target.pauseVideo();
    }
  };
  function handleClose() {
    dispatch({ type: "setShow", payload: false });
  }

  const [saveSupport] = useMutation(VideoSupportGql);

  useEffect(() => {
    dispatch({ type: "setVideoUrl", payload: propsData.vListIndx.video });
    dispatch({ type: "setVListIndx", payload: propsData.vListIndx.id });
    dispatch({ type: "setLinkTitle", payload: propsData.vListIndx.title });
    dispatch({ type: "setOtherLink", payload: propsData.vListIndx.otherLink });
  }, [
    propsData.vListIndx.id,
    propsData.vListIndx.otherLink,
    propsData.vListIndx.title,
    propsData.vListIndx.video,
  ]);

  let videoCode;
  if (state.videoUrl !== "unload") {
    videoCode = state.videoUrl.split("v=")[1].split("&")[0];
  }
  const opts: any = {
    height: "400",
    width: "100%",
    playerVars: {
      autoplay: false,
      start: 0,
      loop: 1,
    },
  };

  const videoLinkClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    urlCode: string,
    vid: string,
    linkTitle: string,
    otherLink: any
  ) => {
    e.preventDefault();
    dispatch({ type: "setTimer", payload: 0 });
    dispatch({ type: "setVideoUrl", payload: urlCode });
    dispatch({ type: "setVListIndx", payload: vid });
    dispatch({ type: "setLinkTitle", payload: linkTitle });
    dispatch({ type: "setOtherLink", payload: otherLink });
    window.scrollTo(0, 0);
  };

  const onPlayAction = (e: any) => {
    const duration = e.target.getDuration();
    dispatch({ type: "setTarget", payload: e.target });
    if (state.intervalID !== "") {
      clearInterval(state.intervalID);
    }
    const currentTime = e.target.getCurrentTime();
    dispatch({ type: "setTimer", payload: 0 });
    if (currentTime > 0) {
      const interval = setInterval(function () {
        if (e.target.getCurrentTime() !== undefined) {
          dispatch({ type: "setInterverID", payload: e.target });
          dispatch({ type: "setTimer", payload: e.target.getCurrentTime() });
        }
        if (e.target.getCurrentTime() >= duration - 2) {
          dispatch({ type: "setTimer", payload: 0 });
          clearInterval(interval);
        }
        dispatch({ type: "setInterverID", payload: interval });
      }, 1000);
    }
  };

  const sendRequest = async (e: any) => {
    dispatch({ type: "setReqStatus", payload: "Sending..." });

    let getTime = Math.trunc(state.timer);

    const res = await saveSupport({
      variables: {
        data: {
          appeared_by: {
            connect: {
              id: ctx.userId,
            },
          },
          module: {
            connect: {
              id: params.id,
            },
          },
          startAt: getTime.toString(),
          video: {
            connect: {
              id: state.vListIndx,
            },
          },
        },
      },
    });
    if (res.data.createvideoSupport.id) {
      dispatch({ type: "setReqStatus", payload: "Request Sent" });

      setTimeout(
        () => dispatch({ type: "setReqStatus", payload: "Get Support" }),
        5000
      );
    }
  };
  const startTest = (e: any) => {
    push("/mso/ModuleTest/" + params.id);
  };

  return (
    <>
      <Row>
        <Col className="card-wrapper pl-0 pr-0" xl="9">
          <Card>
            <CardBody md="12" xl="12" className="pl-2 pr-2">
              <div style={{ textAlign: "left" }}>
                <h3>{state.linkTitle}</h3>
              </div>
              {state.videoUrl !== "unload" ? (
                <YouTube
                  videoId={videoCode}
                  containerClassName="embed embed-youtube"
                  onPlay={(e) => onPlayAction(e)}
                  opts={opts}
                  id="testingu"
                />
              ) : (
                <Loading />
              )}

              <div style={{ textAlign: "center" }}>
                {state.timer > 25 ? (
                  <button
                    className="btn btn-outline-primary mb-1"
                    onClick={(e) => sendRequest(e)}
                  >
                    {" "}
                    {state.reqStatus}
                  </button>
                ) : (
                  ""
                )}
                <button
                  className="btn btn-default mb-1"
                  onClick={(e) => startTest(e)}
                >
                  Start Test
                </button>
                <button
                  className="btn btn-outline-secondary mb-1"
                  onClick={handleShow}
                >
                  Query
                </button>
              </div>
              {state.otherLink !== "" && state.otherLink.length > 0 ? (
                <Row xl="12">
                  <Col xl="12">
                    <h5>Additional Resource </h5>
                  </Col>
                  <ExternalLink data={state.otherLink} />
                </Row>
              ) : (
                ""
              )}
            </CardBody>
          </Card>
        </Col>

        <Col className="card-wrapper mb-4" xl="3">
          <div style={{ width: "100%" }} className="text-center">
            <h5>Related Topics </h5>
          </div>
          <VideoList
            data={data}
            linkClick={videoLinkClick}
            vListIndx={state.vListIndx}
            minHeight="0px"
            xl="12"
          />
          <TopicQuery
            id={params.id}
            vListIndx={state.vListIndx}
            show={state.show}
            handleClose={handleClose}
          />
        </Col>
      </Row>
    </>
  );
};

export default VideoPlayer;
