import {
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
} from "reactstrap";
import { useState } from "react";
import classnames from "classnames";
import { MobileFormTypes } from "../../models/LoginTypes";
const MobileForm = (data: MobileFormTypes): JSX.Element => {
  const Disabled = data.value.length < 10 ? true : false;
  const [phoneNumber, setphoneNumber] = useState(false);
  return (
    <>
      <Form>
        <Col md="12" xl="12">
          <FormGroup>
            <InputGroup
              className={classnames("input-group-merge", {
                focused: phoneNumber,
              })}
            >
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-phone" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Enter Mobile No."
                type="text"
                onChange={data.onChange}
                onFocus={(e) => setphoneNumber(true)}
                onBlur={(e) => setphoneNumber(false)}
                value={data.value}
                className="input"
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col md="12" xl="12">
          <div className="text-center">
            <button
              type="submit"
              className="btn btn-primary texth3"
              disabled={Disabled}
              onClick={(e) => data.onClick(e)}
            >
              Get OTP
            </button>
          </div>
        </Col>
      </Form>
    </>
  );
};

export default MobileForm;
