import { Button, UncontrolledTooltip } from "reactstrap";
import { useHistory } from "react-router-dom";
const Back = (data: any): JSX.Element => {
  const history = useHistory();  
  return (
    <div>
      <Button
        className="btn-round btn-icon"
        color="primary"
        id="tooltip443412080"
        onClick={() => history.goBack()}
        size="sm"
      >
        <span className="btn-inner--icon mr-1">
          <i className="fas fa-arrow-left" />
        </span>
        <span className="btn-inner--text">Back</span>
      </Button>
      <UncontrolledTooltip delay={0} target="tooltip443412080">
        Back
      </UncontrolledTooltip>
    </div>
  );
};

export default Back;
