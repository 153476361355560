import { TestQuesRadioType } from "../../../models/TestTypes";
const RadioButton = (data: TestQuesRadioType): JSX.Element => {
  return (
    <div className="custom-control custom-radio mb-3">
      <input
        className="custom-control-input"
        value={data.id}
        checked={
          data.ids.length > 0 &&
          data.ids.findIndex((item) => item.id === data.id) !== -1
            ? true
            : false
        }
        name="msoInput"
        onChange={data.onChange}
        id={data.id}
        type="radio"
      />
      <label className="custom-control-label" htmlFor={data.id}>
        {data.label}
      </label>
    </div>
  );
};
export default RadioButton;
