import { Row, Col, Button } from "reactstrap";
import { useState, useContext } from "react";
import { istDateTime } from "../../utils";
import YouTube from "react-youtube";
import { LoginContext } from "../../context/loginContext";
import ApproveVideoRequest from "./ApproveVideoRequest";
import "./style.css";
import { SupportDetailsType } from "../../models/VideoSupportType";
const VideoDetails = (data: SupportDetailsType): JSX.Element => {
  const ctx = useContext(LoginContext);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <>
      <Row xl="12">
        <Col xl="8">
          <YouTube
            videoId={data.videoId}
            containerClassName="embed embed-youtube"
            opts={data.opts}
          />
        </Col>
        <Col xl="4">
          {ctx.role === "reviewer" ? (
            <Row xl="12" className="rowDetails">
              <Button
                color="btn btn-outline-info p-2 pl-5 pr-5"
                onClick={handleShow}
              >
                Approve It
              </Button>
            </Row>
          ) : (
            ""
          )}
          <Row xl="12" className="rowDetails">
            Created At - <b>{istDateTime(data.detail.createdAt)}</b>
          </Row>
          {ctx.role === "reviewer" ? (
            <Row xl="12" className="rowDetails">
              <span>
                MSO - <b>{data.detail.appeared_by.name}</b>
              </span>
            </Row>
          ) : (
            ""
          )}
          {ctx.role === "reviewer" ? (
            <Row xl="12" className="rowDetails">
              Contact - <b>{data.detail.appeared_by.mobile}</b>
            </Row>
          ) : (
            ""
          )}
          <Row xl="12" className="rowDetails">
            Topic's Module - <b>{data.detail.module.title}</b>
          </Row>
          <Row xl="12" className="rowDetails">
            <b>Topic's Title </b>- {data.detail.video.title}
          </Row>
          {data.detail.query !== "" ? (
            <Row xl="12" className="rowDetails">
              <b>Query -</b> {data.detail.query}
            </Row>
          ) : (
            ""
          )}
          {data.detail.startAt !== "0.00" ? (
            <Row xl="12" className="rowDetails">
              <b>Problem At </b>- <b>{data.detail.startAt} Seconds</b>
            </Row>
          ) : (
            ""
          )}
          {data.detail.support_notes !== "" ? (
            <Row xl="12" className="rowDetails">
              <b>Reply -</b> {data.detail.support_notes}
            </Row>
          ) : (
            ""
          )}
          <Row xl="12" className="rowDetails">
            Status -{" "}
            <b>
              {data.detail.support_status === "Created"
                ? "Pending"
                : "Resolved"}
            </b>
          </Row>
        </Col>
      </Row>
      <ApproveVideoRequest
        show={show}
        detail={data.detail}
        handleClose={handleClose}
      />
    </>
  );
};

export default VideoDetails;
