import { Col } from "reactstrap";
import ReactPlayer from "react-player";
import { arrayValueExist, Allowextennstion } from "../../utils";
import { AttachedFileTypes } from "../../models/TopicTypes";
import PDFVIEW from "components/PDFVIEW/PDFVIEW";
interface propsTypes {
  data: AttachedFileTypes;
}
const AttachedFile = (propsData: propsTypes): JSX.Element => {
  let ext: any = "";
  if (propsData.data !== null && propsData.data.filename !== undefined) {
    ext = propsData.data.filename.split(".").pop();
    ext = ext.toLowerCase();
  }

  let height = ext === ("mp3" || "wav") ? "50px" : "250px";

  return (
    <>
      <Col xl="12" md="12" className="pl-0 pr-0" >
        {arrayValueExist(Allowextennstion("video"), ext) ? (
          <div>
            <ReactPlayer
              width="100%"
              height={height}
              url={propsData.data.url}
              controls={true}
            />
          </div>
        ) : arrayValueExist(Allowextennstion("image"), ext) ? (
          <img src={propsData.data.url} alt={propsData.data.url} width="100%" />
        ) : arrayValueExist(Allowextennstion("pdf"), ext) ? (
          <div>
            <PDFVIEW file={propsData.data.url} />
          </div>
        ) : (
          <a href={propsData.data.url} target="_blank" rel="noreferrer">
            <i
              className="fas fa-file fa-6"
              style={{ color: "#30c9b4", fontSize: "4em" }}
            />
            <br />
            Download
          </a>
        )}
      </Col>
    </>
  );
};

export default AttachedFile;
