import {  toast } from "react-toastify";
export function signUpReducer(state: any, action: any) {
  switch (action.type) {
    case "setInteger": {
      let input = action.payload.replace(/\D/g, "");
      return {
        ...state,
        [action.fieldName]:
          input.length <= action.length ? input : state[action.fieldName],
      };
    }
    case "setText": {
      let input = action.payload;
      return {
        ...state,
        [action.fieldName]:
          input.length <= action.length ? input : state[action.fieldName],
      };
    }

    case "setEmail": {
      let input = action.payload;
      const regex = new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      if (input !== "" && regex.test(input) === false) {
        toast.error("Entered wrong email");
      }

      return {
        ...state,
        emailFocus: true,
        email: regex.test(input) === false ? input : input,
      };
    }

    case "setFocus": {
      let input = action.payload;
      return {
        ...state,
        [action.fieldName]: input,
      };
    }
    case "setLoader": {
      return {
        ...state,
        error: "",
        loader: action.payload,
      };
    }

    default:
      return state;
  }
}

export const initialState = {
  loader: false,
  name: "",
  email: "",
  mobile: "",
  pincode: "",
  nameFocus: false,
  emailFocus: false,
  mobileFocus: false,
  pincodeFocus: false,
};
