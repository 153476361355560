import { Row, Col } from "reactstrap";
import { useContext } from "react";
import { DocumentRenderer } from "@keystone-6/document-renderer";
import OpenView from "./OpenText";
import ChoiceView from "./Choice";
import GiveMarks from "./GiveMarks";
import { QuesViewTypes } from "../../models/QuesViewTypes";

import { LoginContext } from "../../context/loginContext";
const QuesView = (Testdata: QuesViewTypes): JSX.Element => {
  const ctx = useContext(LoginContext);
  const data = Testdata.data;
  return (
    <>
      {(data.isAttempted === Testdata.QuesViewType &&
        Testdata.status === "view") ||
      (data.isAttempted === Testdata.QuesViewType &&
        data.question.type === "open_text" &&
        Testdata.status === "review") ? (
        <Row xl="12" className="p-1">
          <Col xl="12">
            <span style={{ color: "#ff6600" }}>
              <strong>Q #{data.question_no}) </strong>
            </span>{" "}
            <DocumentRenderer document={data.question.statement.document} />
          </Col>
          {data.question.type === "open_text" ? (
            <OpenView data={data} />
          ) : (
            <ChoiceView data={data} />
          )}
          {Testdata.status === "view" && ctx.role !== "mso" ? (
            <Col xl="12">
              Score: <b>{data.score}</b>
              <hr className="mt-1 mb-1" />
            </Col>
          ) : ctx.role !== "mso" ? (
            <GiveMarks id={data.id} score={data.score} />
          ) : (
            <Col xl="12">
              <hr className="mt-1 mb-1" />{" "}
            </Col>
          )}
        </Row>
      ) : (
        ""
      )}
    </>
  );
};

export default QuesView;
