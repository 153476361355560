import Dashboard from "../../pages/mso/Dashboard";
import MSODashboard from "../../pages/reviewer/Dashboard";
import { LogOut } from "../../pages/Login/index";
import ModuleVideo from "../../pages/mso/ModuleVideo";
import CompletedTest from "../../pages/mso/List/CompletedTest";
import ModuleTest from "../../pages/mso/ModuleTest/ModuleTest";
import TestQuestions from "../../pages/mso/List/TestQuestion";
import ReviewTestList from "../../pages/reviewer/test/Test";
import MSOReport from "../../pages/reviewer/report/MSOReport";
import VideoSupport from "../../pages/reviewer/VideoSupport/VideoSupport";
import QuerySupport from "../../pages/reviewer/VideoSupport/QuerySupport";
import ViewSupportDetails from "../../pages/reviewer/VideoSupport/ViewSupportDetails";
const RouteData: any = {
  mso: [
    {
      collapse: true,
      name: "MSO Training",
      icon: "ni ni-shop text-primary",
      state: "unload",
      miniName: "",
      display: "yes",
      path: "/mso/dashboard",
      component: Dashboard,
      views: [
        {
          path: "/mso/dashboard",
          name: "Dashboard",
          miniName: "DS",
          component: Dashboard,
          layout: "mso",
          display: "yes",
        },
        {
          path: "/mso/module",
          name: "Module Result",
          miniName: "MR",
          component: Dashboard,
          layout: "mso",
          display: "no",
        },
        {
          path: "/mso/VideoSupport",
          name: "Topic Video Support",
          miniName: "TVS",
          component: VideoSupport,
          layout: "mso",
          display: "yes",
        },
        {
          path: "/mso/QuerySupport",
          name: "Topic Query Support",
          miniName: "TQS",
          component: QuerySupport,
          layout: "mso",
          display: "yes",
        },
        {
          path: "/mso/ViewSupportDetails/:id",
          name: "Support Details",
          miniName: "SD",
          component: ViewSupportDetails,
          layout: "mso",
          display: "no",
        },
        {
          path: "/mso/module",
          name: "Learning Support",
          miniName: "LS",
          component: Dashboard,
          layout: "mso",
          display: "no",
        },
        {
          path: "/mso/ModuleVideo/:id",
          name: "Learning Video",
          miniName: "LS",
          component: ModuleVideo,
          layout: "mso",
          display: "no",
        },
        {
          path: "/mso/ModuleTest/:id",
          name: "Test",
          miniName: "LS",
          component: ModuleTest,
          layout: "mso",
          display: "no",
        },
        {
          path: "/mso/CompletedTest/:id",
          name: "CompletedTest",
          miniName: "LS",
          component: CompletedTest,
          layout: "mso",
          display: "no",
        },
        {
          path: "/mso/TestQuestions/:id/:status",
          name: "TestQuestions",
          miniName: "LS",
          component: TestQuestions,
          layout: "mso",
          display: "no",
        },

        {
          path: "/logout",
          name: "Logout",
          miniName: "LG",
          component: LogOut,
          layout: "mso",
          display: "yes",
        },
      ],
    },
  ],
  reviewer: [
    {
      collapse: true,
      name: "Reviewer",
      icon: "ni ni-shop text-primary",
      state: "gghgh",
      path: "/reviewer/dashboard",
      miniName: "",
      component: MSODashboard,
      views: [
        {
          path: "/reviewer/dashboard",
          name: "Dashboard",
          miniName: "DS",
          component: MSODashboard,
          layout: "reviewer",
          display: "yes",
        },
        {
          path: "/reviewer/ReviewTestList/:type",
          name: "Module Result",
          miniName: "MR",
          component: ReviewTestList,
          layout: "reviewer",
          display: "no",
        },
        {
          path: "/reviewer/TestQuestions/:id/:status",
          name: "Test Question",
          miniName: "MR",
          component: TestQuestions,
          layout: "reviewer",
          display: "no",
        },
        {
          path: "/reviewer/ViewSupportDetails/:id",
          name: "Support Details",
          miniName: "SD",
          component: ViewSupportDetails,
          layout: "reviewer",
          display: "no",
        },
        {
          path: "/reviewer/MSOReport",
          name: "MSO Report",
          miniName: "MR",
          component: MSOReport,
          layout: "reviewer",
          display: "yes",
        },
        {
          path: "/reviewer/VideoSupport",
          name: "Topic Video Support",
          miniName: "VS",
          component: VideoSupport,
          layout: "reviewer",
          display: "yes",
        },
        {
          path: "/mso/QuerySupport",
          name: "Topic Query Support",
          miniName: "TQS",
          component: QuerySupport,
          layout: "reviewer",
          display: "yes",
        },
        {
          path: "/logout",
          name: "Logout",
          miniName: "LG",
          component: LogOut,
          layout: "reviewer",
          display: "yes",
        },
      ],
    },
  ],
};

export default RouteData;
