import { Button, Modal, ModalFooter, ModalBody, Input } from "reactstrap";
import { useContext } from "react";
import { useMutation } from "@apollo/client";
import { LoginContext } from "../../context/loginContext";
import Loading from "../../components/Loading";
import { useReducer } from "react";
import { VideoSupportGql } from "../../graphql/Video";
import AskedQuery from "./AshkedQuery";
import { ToastContainer, toast } from "react-toastify";
import { videoReducer, initialState } from "../../reducer/videoReducer";
const TopicQuery = (data: any): JSX.Element => {
  const [state, dispatch] = useReducer(videoReducer, initialState);
  const ctx = useContext(LoginContext);
  const [saveSupport] = useMutation(VideoSupportGql);

  const inputHandler = async (event: React.FocusEvent<HTMLInputElement>) => {
    dispatch({ type: "setQuery", payload: event.target.value });
  };

  const submitHandler = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (state.query.length < 20) {
      toast.error("Please enter 20 character for query");
    } else {
      dispatch({ type: "setQueryLoader", payload: true });
      const res = await saveSupport({
        variables: {
          data: {
            appeared_by: {
              connect: {
                id: ctx.userId,
              },
            },
            module: {
              connect: {
                id: data.id,
              },
            },
            query: state.query,
            type: "Query",
            video: {
              connect: {
                id: data.vListIndx,
              },
            },
          },
        },
      });
      if (res.data.createvideoSupport.id) {
        toast.success("Query sent successfully");
        dispatch({ type: "setQuery", payload: "" });

        setTimeout(() => {
          dispatch({ type: "setQueryLoader", payload: false });
          data.handleClose();
        }, 100);
      }
    }
  };

  return (
    <>
      {!state.queryLoader ? (
        <Modal isOpen={data.show}>
          <div className="p-2">
            <b>Topic related query</b>
          </div>
          <ModalBody className="p-1 pr-3">
            <div className="custom-control  mb-3">
              <Input
                id="msoQuery"
                name="msoQuery"
                value={state.query}
                onChange={inputHandler}
                rows="3"
                type="textarea"
              />
            </div>
            <div>
              <AskedQuery userId={ctx.userId} video={data.vListIndx} />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={(e) => submitHandler(e)}>
              Submit
            </Button>
            <Button color="secondary" onClick={data.handleClose}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      ) : (
        <Loading />
      )}
      <ToastContainer />
    </>
  );
};

export default TopicQuery;
