import { Col } from "reactstrap";
import { DocumentRenderer } from "@keystone-6/document-renderer";
import { AdditionalresourceTypes } from "../../models/TopicTypes";
import AttachedFile from "../../components/modulesTopic/AttachedFile";
import URLVideoPlayer from "../../components/video/URLVideoPlayer";
interface propsTypes {
  data: [AdditionalresourceTypes];
}
const ExternalLink = (propsData: propsTypes): JSX.Element => {
  const ExtLinkData: AdditionalresourceTypes[] = propsData.data;
  return (
    <>
      {ExtLinkData.map((data, index: number) => (
        <div key={index} style={{ minWidth: "100%" }}>
          {data.active ? (
            <Col
              xl="12"
              md="12"
              className="border rounded-5 p-2 mb-2 pl-3 pr-3"
              style={{ border: "1px solid grey" }}
            >
              <h4>{data.title}</h4>

              <URLVideoPlayer url={data.url} />

              {data.attachedFile !== null && data.attachedFile !== undefined ? (
                <div>
                  <AttachedFile data={data.attachedFile} />
                </div>
              ) : (
                ""
              )}
              <div className="text-justify">
                <DocumentRenderer document={data.content.document} />
              </div>
            </Col>
          ) : (
            ""
          )}
        </div>
      ))}
    </>
  );
};

export default ExternalLink;
