const VideoSupportTbl = [
  {
    name: "MSO",
    selector: "mso",
  },
  {
    name: "Topic",
    selector: "title",
  },
  {
    name: "Contact",
    selector: "contact",
  },

  {
    name: "Created At",
    selector: "createdAt",
  },
  {
    name: "Status",
    selector: "status",
  },
  {
    name: "",
    selector: "view",
  },
];
export default VideoSupportTbl;
