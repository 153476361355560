import {
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  CardBody,
  CardText,
  CardHeader,
} from "reactstrap";
import { useEffect, useReducer } from "react";
import { useParams } from "react-router";
import VideoList from "./VideoList";
import { subjectGql } from "../../graphql/moduleTest";
import { useLazyQuery } from "@apollo/client";
import { testReducer, initialState } from "../../reducer/testReducer";
import { GetMSODashboardGql } from "../../graphql/dashboard";
import ModuleList from "./ModuleList";
import Loading from "../../components/Loading";
import { TopicDetailTypes, selectTopicTypes } from "../../models/TopicTypes";
interface propsTypes {
  data: [TopicDetailTypes];
  selectTopic: (index: selectTopicTypes) => void;
}
const SubjectTopics = (propsData: propsTypes): JSX.Element => {
  const [state, dispatch] = useReducer(testReducer, initialState);
  const topicList: TopicDetailTypes[] = propsData.data;
  const [loadmoduleData] = useLazyQuery(subjectGql);
  const [loadModuleList] = useLazyQuery(GetMSODashboardGql);
  const params: { id: string } = useParams();

  const currentIndexData = "";
  useEffect(() => {
    async function fetchMyAPI() {
      dispatch({ type: "setLoader", payload: true });
      const subRes = await loadmoduleData({
        variables: { where: { id: { equals: params.id } } },
      });
      if (!subRes.loading) {
        dispatch({ type: "setModule", payload: subRes.data.items[0] });
        const res = await loadModuleList({
          variables: {
            orderBy: [{ seqno: "asc" }],
          },
        });
        if (!res.loading) {
          dispatch({ type: "setList", payload: res.data.items });
          dispatch({ type: "setLoader", payload: false });
        }
      }
    }
    fetchMyAPI();
  }, [params.id, loadModuleList, loadmoduleData]);
  const videoLinkClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    url: string,
    vid: string,
    linkTitle: string,
    otherLink: any
  ) => {
    e.preventDefault();

    propsData.selectTopic({
      video: url,
      id: vid,
      title: linkTitle,
      otherLink: otherLink,
    });
  };

  return (
    <>
      <Container className="mt-2 pl-0 pr-0" fluid>
        <Row xl="12">
          <Col
            className="card-wrapper mb-4"
            xl="9"
            md="9"
            style={{ maxHeight: "500px", overflow: "auto" }}
          >
            <Card>
              <CardHeader className="p-1 pl-2">
                <CardTitle className="mb-1">
                  <CardText>
                    Selected Subject:-{" "}
                    <span className="custHeading">
                      {state.moduleName.title}
                    </span>
                  </CardText>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Row xl="12">
                  <VideoList
                    data={topicList}
                    linkClick={videoLinkClick}
                    vListIndx={currentIndexData}
                    xl="4"
                    minHeight="105px"
                  />
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col className="card-wrapper mb-4" xl="3" md="3">
            <Row xl="12" md="12" className="pl-4 pr-4">
              {!state.loader ? (
                <ModuleList data={state.list} xl="12" />
              ) : (
                <Loading />
              )}
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SubjectTopics;
