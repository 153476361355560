import { Button, Col, Card, CardTitle, CardBody } from "reactstrap";

const CustomCard = (data: any): JSX.Element => {
  return (
    <Col xl="4">
      <Card className="p-3" style={{ border: "1px solid #e3d9d9" }}>
        <CardTitle className="mb-1 text-center" tag="h5">
          {data.info}
        </CardTitle>
        <CardBody className="text-center" style={{ padding: ".5rem" }}>
          <Button
            color="btn btn-outline-info p-2 pl-5 pr-5"
            onClick={(e) =>
              data.Count > 0
                ? data.handleClick(e, data.status)
                : e.preventDefault
            }
          >
            {data.Count}
          </Button>
        </CardBody>
      </Card>
    </Col>
  );
};

export default CustomCard;
