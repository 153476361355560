import { useEffect, useReducer } from "react";
import { Card, CardHeader, CardBody, Col, Row } from "reactstrap";
import Dropdown from "../../../components/DropDown/Dropdown";
import {
  msoReportReducer,
  initialState,
} from "../../../reducer/MSOReportReducer";
import { MsoModuleReportGQL } from "../../../graphql/report";
import { useLazyQuery } from "@apollo/client";
import { ModuleReportType } from "../../../models/ReportTypes";
import ViewModuleReport from "./viewReport/ViewModuleReport";

const MSOModuleReport = (ModuleReport: ModuleReportType): JSX.Element => {
  const [state, dispatch] = useReducer(msoReportReducer, initialState);
  const [LoadReport] = useLazyQuery(MsoModuleReportGQL, {
    fetchPolicy: "no-cache",
  });

  let moduleList = [];
  for (const value of ModuleReport.moduleList) {
    moduleList.push({ id: value.id, label: value.title });
  }

  async function msoModuleTestReport(msoID: String, moduleID: String) {
    dispatch({ type: "setReportLoader", payload: "loading" });
    const res = await LoadReport({
      variables: {
        msoID: msoID,
        moduleID: moduleID,
      },
    });
    if (!res.loading) {
      dispatch({ type: "setModuleReport", payload: res.data.msoModuleReport });
      dispatch({ type: "setReportLoader", payload: "show" });
    }
  }

  const SelectedItem = async (val: any) => {
    ModuleReport.currentModule(val);
    if (val !== "") {
      msoModuleTestReport(ModuleReport.selectedMSO, val);
    } else {
      dispatch({ type: "setReportLoader", payload: "hide" });
    }
  };
  useEffect(() => {
    async function msoModuleTestReportOnChange(
      msoID: String,
      moduleID: String
    ) {
      dispatch({ type: "setReportLoader", payload: "loading" });
      const res = await LoadReport({
        variables: {
          msoID: msoID,
          moduleID: moduleID,
        },
      });
      if (!res.loading) {
        dispatch({
          type: "setModuleReport",
          payload: res.data.msoModuleReport,
        });
        dispatch({ type: "setReportLoader", payload: "show" });
      }
    }
    if (ModuleReport.selectedMSO !== "" && ModuleReport.selectedModule !== "") {
      msoModuleTestReportOnChange(
        ModuleReport.selectedMSO,
        ModuleReport.selectedModule
      );
    }
  }, [ModuleReport.selectedModule, ModuleReport.selectedMSO, LoadReport]);

  return (
    <>
      <Col xl="12">
        <Card className="p-1">
          <CardHeader className="bg-transparent p-1 pb-2">
            <Row xl="12">
              <Col xl="12" className="text-center pb-2">
                <h5 className="ls-1 mb-0">MSO Subject Test Report</h5>
              </Col>

              <Col xl="12">
                <Dropdown
                  onChange={SelectedItem}
                  options={moduleList}
                  placeholder="Select Subject"
                />
              </Col>
            </Row>
          </CardHeader>
          <CardBody md="12" xl="12" className="p-0 minHeight">
            {state.reportLoader === "show" ? (
              <ViewModuleReport data={state.moduleReport} />
            ) : state.reportLoader === "loading" ? (
              <div className="loading">Loading...</div>
            ) : (
              <div className="dispMsg">Report Display Here</div>
            )}
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default MSOModuleReport;
