import { Card, CardHeader, Container, Row, Col, CardBody } from "reactstrap";
import { useParams } from "react-router";
import { useLazyQuery } from "@apollo/client";
import Loading from "../../../components/Loading";
import CardsHeader from "../../../components/Header/CardsHeader";
import { useEffect, useReducer } from "react";
import VideoDetails from "../../../components/video/videoDetails";
import Back from "../../../components/Back/back";
import { VideoSupportListGql } from "../../../graphql/Video";
import { videoReducer, initialState } from "../../../reducer/videoReducer";
const ViewSupportDetails = (): JSX.Element => {
  const params: { id: string } = useParams();
  const [state, dispatch] = useReducer(videoReducer, initialState);

  const [loadPendingSupport] = useLazyQuery(VideoSupportListGql);

  const noOfDataPerPage = 1;

  useEffect(() => {
    async function fetchData(Page: number) {
      dispatch({ type: "setLoader", payload: true });
      const res = await loadPendingSupport({
        variables: {
          orderBy: [{ createdAt: "desc" }],
          where: { id: { equals: params.id } },
          take: noOfDataPerPage,
          skip: Page * noOfDataPerPage,
        },
      });
      if (!res.loading) {
        if (res.data.items.length > 0) {
          const resData = res.data.items[0];
          const videoCode = resData.video.video.split("v=")[1].split("&")[0];
          dispatch({ type: "setDetail", payload: resData });
          dispatch({ type: "setVideoCode", payload: videoCode });
          const playerOtp = {
            height: "400",
            width: "100%",
            playerVars: {
              autoplay: false,
              start: parseFloat(resData.startAt),
              loop: 1,
            },
          };

          dispatch({ type: "setPlayerOtp", payload: playerOtp });

          dispatch({ type: "setLoader", payload: false });
        }
        dispatch({ type: "setLoader", payload: false });
      }
    }
    fetchData(0);
  }, [loadPendingSupport, params.id]);

  return (
    <>
      <CardsHeader name="MSO Traning" parentName="Topic Video Support" />
      {!state.loader ? (
        <Container className="mt-2 pl-0 pr-0" fluid>
          <Col className="card-wrapper pl-0 pr-0" xl="12">
            <Card>
              <CardHeader className="border-0 p-1 pl-3">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">Topic's Video Support Details</h3>
                  </Col>
                  <Col className="text-right" xs="6">
                    <Back />
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="p-1">
                {!state.loader ? (
                  <VideoDetails
                    videoId={state.videoCode}
                    detail={state.detail}
                    opts={state.playerOtp}
                  />
                ) : (
                  <Loading />
                )}
              </CardBody>
            </Card>
          </Col>
        </Container>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default ViewSupportDetails;
