import { Card, CardHeader, Container, Row, Col, CardBody } from "reactstrap";
import { istDateTime } from "../../../utils";
import { useHistory } from "react-router";
import { useLazyQuery } from "@apollo/client";
import Loading from "../../../components/Loading";
import CardsHeader from "../../../components/Header/CardsHeader";
import { useEffect, useReducer, useContext, useCallback } from "react";
import TableList from "../../../components/Table/DataTable";
import Back from "../../../components/Back/back";
import VideoSupportTbl from "../../../pages/TableColumn/reviewer/VideoSupportTbl";
import { VideoSupportListGql } from "../../../graphql/Video";
import {
  TableListReducer,
  ListInitialState,
} from "../../../reducer/tableListReducer";
import { LoginContext } from "../../../context/loginContext";
const VideoSupport = (): JSX.Element => {
  const ctx: any = useContext(LoginContext);
  const [state, dispatch] = useReducer(TableListReducer, ListInitialState);
  const { push } = useHistory();
  const [loadPendingSupport]: any = useLazyQuery(VideoSupportListGql);

  const noOfDataPerPage = 10;

  const viewDetails = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    tid: string
  ) => {
    e.preventDefault();
    push("/" + ctx.role + "/ViewSupportDetails/" + tid);
  };

  const viewMoreCllBck = useCallback(
    (e, tid) => {
      e.preventDefault();
      push("/" + ctx.role + "/ViewSupportDetails/" + tid);
    },
    [ctx.role, push]
  );

  async function fetchData(Page: number) {
    dispatch({ type: "setLoader", payload: true });
    let where: any = {
      appeared_by: { id: { in: [ctx.userId] } },
      type: { in: ["Video"] },
    };
    if (ctx.role === "reviewer") {
      where = { support_status: { in: ["Created"] }, type: { in: ["Video"] } };
    }
    const res = await loadPendingSupport({
      variables: {
        orderBy: [{ createdAt: "desc" }],
        where: where,
        take: noOfDataPerPage,
        skip: Page * noOfDataPerPage,
      },
    });
    if (!res.loading) {
      const dataList: any = [];
      if (res.data.items.length > 0) {
        dispatch({ type: "setTableLength", payload: res.data.count });

        for (const data of res.data.items) {
          dataList.push({
            id: data.id,
            mso: data.appeared_by.name,
            title: (
              <a
                href="/#"
                title="View"
                style={{ cursor: "pointer" }}
                onClick={(e) => viewDetails(e, data.id)}
              >
                {data.video.title}
              </a>
            ),
            contact: data.appeared_by.mobile,
            createdAt: istDateTime(data.createdAt),
            status: data.support_status === "Created" ? "Pending" : "Resolved",
            view: (
              <a
                title="View"
                href="/#"
                style={{ cursor: "pointer" }}
                onClick={(e) => viewDetails(e, data.id)}
              >
                View
              </a>
            ),
          });
        }

        dispatch({ type: "setCurrentPage", payload: Page });
        dispatch({ type: "setTableList", payload: dataList });
      }
      dispatch({ type: "setLoader", payload: false });
    }
  }

  useEffect(() => {
    async function fetchFirst(Page: number) {
      dispatch({ type: "setLoader", payload: true });
      let where: any = {
        appeared_by: { id: { in: [ctx.userId] } },
        type: { in: ["Video"] },
      };
      if (ctx.role === "reviewer") {
        where = {
          support_status: { in: ["Created"] },
          type: { in: ["Video"] },
        };
      }
      const res = await loadPendingSupport({
        variables: {
          orderBy: [{ createdAt: "desc" }],
          where: where,
          take: noOfDataPerPage,
          skip: Page * noOfDataPerPage,
        },
      });
      if (!res.loading) {
        const dataList: any = [];
        if (res.data.items.length > 0) {
          dispatch({ type: "setTableLength", payload: res.data.count });
          for (const data of res.data.items) {
            dataList.push({
              id: data.id,
              mso: data.appeared_by.name,
              title: (
                <a
                  href="/#"
                  title="View"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => viewMoreCllBck(e, data.id)}
                >
                  {data.video.title}
                </a>
              ),
              contact: data.appeared_by.mobile,
              createdAt: istDateTime(data.createdAt),
              status:
                data.support_status === "Created" ? "Pending" : "Resolved",
              view: (
                <a
                  title="View"
                  href="/#"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => viewMoreCllBck(e, data.id)}
                >
                  View
                </a>
              ),
            });
          }

          dispatch({ type: "setCurrentPage", payload: Page });
          dispatch({ type: "setTableList", payload: dataList });
        }
        dispatch({ type: "setLoader", payload: false });
      }
    }
    fetchFirst(0);
  }, [ctx.role, ctx.userId, loadPendingSupport, viewMoreCllBck]);

  return (
    <>
      <CardsHeader name="MSO Traning" parentName="Video Support Request" />
      {!state.loader ? (
        <Container className="mt-2 pl-0 pr-0" fluid>
          <Col className="card-wrapper pl-0 pr-0" xl="12">
            <Card>
              <CardHeader className="border-0 p-1 pl-3">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">Topic Video Support</h3>
                  </Col>
                  <Col className="text-right" xs="6">
                    <Back />
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="p-1">
                {state.listData !== "" ? (
                  <TableList
                    TableData={state.listData}
                    Columns={VideoSupportTbl}
                    page={state.currentPage}
                    fetchData={fetchData}
                    dataLength={state.tableLength}
                    noOfDataPerPage={noOfDataPerPage}
                  />
                ) : (
                  <Row className="justify-content-md-center">
                    <h4> Empty Video Support list </h4>
                  </Row>
                )}
              </CardBody>
            </Card>
          </Col>
        </Container>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default VideoSupport;
