import { Card, CardHeader, CardBody, Container, Row, Col } from "reactstrap";
import { useEffect, useContext, useReducer } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import { useHistory, useParams } from "react-router";
import QuesView from "./QuesView";
import "./test.css";
import Loading from "../../../components/Loading";
import { startTest, saveAnswer, updateTest } from "../../../graphql/moduleTest";
import { LoginContext } from "../../../context/loginContext";
import { testReducer, initialState } from "../../../reducer/testReducer";
import { subjectGql } from "../../../graphql/moduleTest";
import { AnsQuesType, TestQuesRadioType } from "../../../models/TestTypes";
import { ToastContainer, toast } from "react-toastify";
const ModuleTest = (): JSX.Element => {
  const [state, dispatch] = useReducer(testReducer, initialState);
  const ctx = useContext(LoginContext);
  const { push } = useHistory();
  const params: { id: string } = useParams();
  const [loadmoduleData] = useLazyQuery(subjectGql);
  const [loadQuesList] = useMutation(startTest, { fetchPolicy: "no-cache" });
  const [saveQuesAns] = useMutation(saveAnswer);
  const [setTestStatus] = useMutation(updateTest);
  useEffect(() => {
    async function fetchMyAPI() {
      const subRes = await loadmoduleData({
        variables: { where: { id: { equals: params.id } } },
      });
      if (!subRes.loading) {
        dispatch({ type: "setModule", payload: subRes.data.items[0] });
        const res = await loadQuesList({
          variables: {
            module_id: params.id,
            appeared_by: ctx.userId,
            language: ctx.language,
          },
        });
        const loadQuestion = res.data.StartTest.questionList;

        if (loadQuestion.length > 0) {
          dispatch({ type: "setQuesList", payload: loadQuestion });
          dispatch({
            type: "setCurrQues",
            payload: loadQuestion[state.quesIndex],
          });

          loadQuestion[state.quesIndex].question.type === "open_text"
            ? dispatch({
                type: "SetAnswer",
                payload: loadQuestion[state.quesIndex].textAnswer,
              })
            : dispatch({
                type: "SetAnswer",
                payload: loadQuestion[state.quesIndex].selectedAnser,
              });

          dispatch({ type: "setLoader", payload: false });
        }
      }
    }

    fetchMyAPI();
  }, []);

  const testUpdate = async (
    e: React.MouseEvent<HTMLButtonElement>,
    status: String
  ) => {
    e.preventDefault();
    dispatch({ type: "setLoader", payload: true });
    const res = await setTestStatus({
      variables: {
        TestID: state.quesList[0].test.id,
        is_final: state.moduleName.is_final,
        test_status: status,
      },
    });
    if (res.data.ApproveTestAuto.message !== "") {
      toast.success("Test is successfully " + status);
      setTimeout(() => {
        if (status === "Dropped") {
          push("/mso/dashboard");
        } else {
          push("/mso/TestQuestions/" + state.quesList[0].test.id + "/view");
        }
      }, 1000);
    }
  };

  const quesChange = (
    e: React.MouseEvent<HTMLButtonElement>,
    index: number
  ) => {
    e.preventDefault();
    const quesData = state.quesList[index];
    quesData.question.type === "open_text"
      ? dispatch({ type: "SetAnswer", payload: quesData.textAnswer })
      : dispatch({ type: "SetAnswer", payload: quesData.selectedAnser });

    dispatch({ type: "setQIndex", payload: index });
    dispatch({ type: "setCurrQues", payload: state.quesList[index] });
  };
  const radioHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
    let connDisconn = {};
    let isAttempted: boolean = true;

    const selectedId = event.target.value;
    if (state.answer.length > 0) {
      connDisconn = {
        disconnect: { id: state.answer[0].id },
        connect: { id: selectedId },
      };
    } else {
      connDisconn = {
        connect: { id: selectedId },
      };
    }

    const res = await saveQuesAns({
      variables: {
        data: { selectedAnser: connDisconn, isAttempted: isAttempted },
        id: state.quesList[state.quesIndex].id,
      },
    });
    if (res.data.item.msg !== "") {
      dispatch({ type: "SetAnswer", payload: [{ id: selectedId }] });
    }
  };

  const inputOnchange = (event: React.FocusEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    dispatch({ type: "SetAnswer", payload: [inputValue] });
  };
  const inputHandler = async (event: React.FocusEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    let isAttempted: boolean = inputValue !== "" ? true : false;

    const res = await saveQuesAns({
      variables: {
        data: {
          textAnswer: inputValue,
          isAttempted: isAttempted,
        },
        id: state.quesList[state.quesIndex].id,
      },
    });

    if (res.data.item.msg !== "") {
      dispatch({ type: "SetAnswer", payload: inputValue });
    }
  };

  const MultiChoice = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedId = event.target.value;

    if (event.target.checked) {
      const res = await saveQuesAns({
        variables: {
          data: {
            selectedAnser: {
              connect: [
                {
                  id: selectedId,
                },
              ],
            },
            isAttempted: true,
          },
          id: state.quesList[state.quesIndex].id,
        },
      });
      if (res.data.item.msg !== "") {
        let selectedIds = [...state.answer];
        selectedIds.push({ id: selectedId });
        dispatch({ type: "SetAnswer", payload: selectedIds });
      }
    } else {
      let isAttempted = state.answer.length === 1 ? false : true;
      const res = await saveQuesAns({
        variables: {
          data: {
            selectedAnser: {
              disconnect: [
                {
                  id: selectedId,
                },
              ],
            },
            isAttempted: isAttempted,
          },
          id: state.quesList[state.quesIndex].id,
        },
      });
      if (res.data.item.msg !== "") {
        const newAnswer = state.answer.filter(
          (data: TestQuesRadioType) => data.id !== selectedId
        );
        dispatch({ type: "SetAnswer", payload: newAnswer });
      }
    }
  };

  useEffect(() => {
    if (!state.loader) {
      const quesData = state.quesList[state.quesIndex];
      if (quesData.question.type === "open_text") {
        quesData.textAnswer = state.answer;
      } else {
        quesData.selectedAnser = state.answer;
      }
    }
  });

  const clearAns = async (
    e: React.MouseEvent<HTMLButtonElement>,
    index: number
  ) => {
    const quesData = Object.values<AnsQuesType>(state.quesList)[
      state.quesIndex
    ];
    if (quesData.question.type === "open_text") {
      const res = await saveQuesAns({
        variables: {
          data: {
            textAnswer: "",
            isAttempted: false,
          },
          id: state.quesList[state.quesIndex].id,
        },
      });
      if (res.data.item.msg !== "") {
        dispatch({ type: "SetAnswer", payload: "" });

        quesData.textAnswer = "";
      }
    } else {
      if (quesData.selectedAnser.length > 0) {
        let clearAns = [];
        for (let i = 0; i < quesData.selectedAnser.length; i++) {
          clearAns.push({ id: quesData.selectedAnser[i].id });
        }

        const res = await saveQuesAns({
          variables: {
            data: {
              selectedAnser: {
                disconnect: clearAns,
              },
              isAttempted: false,
            },
            id: state.quesList[state.quesIndex].id,
          },
        });

        if (res.data.item.msg !== "") {
          quesData.selectedAnser = [];
          dispatch({ type: "SetAnswer", payload: [] });
        }
      }
    }
  };

  return (
    <>
      <Container className="mt-2" fluid>
        <Row>
          <Col className="card-wrapper pl-0 pr-0" xl="8" >
            {!state.loader ? (
              <QuesView
                radioHandler={radioHandler}
                ques={state.currentQues}
                QuesIndex={state.quesIndex}
                action={quesChange}
                quesLength={state.quesList.length}
                multiChoice={MultiChoice}
                answer={state.answer}
                clearAns={clearAns}
                inputHandler={inputHandler}
                inputOnchange={inputOnchange}
                moduleName={state.moduleName.title}
                testUpdate={testUpdate}
              />
            ) : (
              <Loading />
            )}
          </Col>

          <Col
            className="card-wrapper mb-5 pl-1 pr-1"
            xl="4"
            style={{ maxHeight: "500px", overflow: "auto" }}
          >
            <Card className="mb-1 mt-2 p-2 ">
              <CardHeader className="custHeading">Question List</CardHeader>
              <CardBody className="pr-4 pl-1">
                <Row xl="12" className="pr-1">
                  {!state.loader
                    ? Object.values(state.quesList).map(
                        (row, index: number) => (
                          <Col
                            className="btn-width"
                            style={{
                              margin: "5px",
                              maxWidth: "50px !important",
                            }}
                            key={index}
                          >
                            <button
                              className={`${
                                state.quesIndex === index
                                  ? "round clicked"
                                  : state.quesList[index].selectedAnser.length >
                                      0 ||
                                    state.quesList[index].textAnswer !== ""
                                  ? "saved"
                                  : "round"
                              }`}
                              onClick={(e) => quesChange(e, index)}
                            >
                              {index + 1}
                            </button>
                          </Col>
                        )
                      )
                    : ""}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
      </Container>
    </>
  );
};

export default ModuleTest;
