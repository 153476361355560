export function testReportReducer(state: any, action: any) {
  switch (action.type) {
    case "setLoader": {
      return {
        ...state,
        error: "",
        loader: action.payload,
      };
    }

    case "setDataLength": {
      return {
        ...state,
        error: "",
        dataLength: action.payload,
      };
    }
    case "setCurrentPage": {
      return {
        ...state,
        error: "",
        currentPage: action.payload,
      };
    }

    case "setTestList": {
      return {
        ...state,
        error: "",
        testList: action.payload,
      };
    }
    default:
      return state;
  }
}

export const initialState = {
  loader: true,
  dataLength: 0,
  currentPage: 0,
  setTestList: "",
  error: "",
};
