import "./style.scss";
const Loading = (): JSX.Element => {
  return (
    <div className="loading">
      <span className="loader"></span>
    </div>
  );
};

export default Loading;
