import { Col } from "reactstrap";
import { QuesViewDataTypes } from "../../models/QuesViewTypes";
interface propsType {
  data: QuesViewDataTypes;
}
const OpenView = (data: propsType): JSX.Element => {
  const quesData = data.data;

  return (
    <>
      <Col xl="12">
        <b>Ans.- </b>
        {quesData.textAnswer}
      </Col>
    </>
  );
};

export default OpenView;
