export function loginReducer(state: any, action: any) {
  switch (action.type) {
    case "setInteger": {
      let input = action.payload.replace(/\D/g, "");
      return {
        ...state,
        [action.fieldName]:
          input.length <= action.length ? input : state[action.fieldName],
      };
    }
    case "setLoader": {
      return {
        ...state,
        error: "",
        loader: action.payload,
      };
    }
    case "setOTPForm": {
      return {
        ...state,
        error: "",
        viewOtpForm: action.payload,
      };
    }
    case "setViewSingupForm": {
      return {
        ...state,
        error: "",
        viewSingupForm: action.payload,
      };
    }
    case "setTimer": {
      return {
        ...state,
        error: "",
        timer: action.payload,
      };
    }
    case "clearOTP": {
      return {
        ...state,
        OTP: "",
      };
    }
    case "ValidMobile": {
      return {
        ...state,
        loader: false,
        timer: false,
        viewOtpForm: true,
        OTP: "",
      };
    }
    case "mobileerror": {
      return {
        ...state,
        error: "Incorrect mobile entered!",
        loader: false,
      };
    }
    case "otperror": {
      return {
        ...state,
        error: "Incorrect OTP entered!",
        loader: false,
        OTP: "",
      };
    }
    default:
      return state;
  }
}

export const initialState = {
  loader: false,
  viewOtpForm: false,
  timer: false,
  viewSingupForm: false,
  mobileNo: "",
  OTP: "",
  error: "",
};
