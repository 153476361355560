import { useReducer } from "react";
import { Card, CardHeader, CardBody, Col, Row } from "reactstrap";
import Dropdown from "../../../components/DropDown/Dropdown";
import { IndividualReportGQL } from "../../../graphql/report";
import ViewIndividualReport from "./viewReport/ViewIndividualReport";
import {
  msoReportReducer,
  initialState,
} from "../../../reducer/MSOReportReducer";
import { useLazyQuery } from "@apollo/client";
import ViewModuleReport from "./viewReport/ViewModuleReport";
import { IndividualReportType } from "../../../models/ReportTypes";
const IndividualReport = (ReportData: IndividualReportType): JSX.Element => {
  const [state, dispatch] = useReducer(msoReportReducer, initialState);
  const [LoadMSOReport]: any = useLazyQuery(IndividualReportGQL, {
    fetchPolicy: "no-cache",
  });

  const GetmsoList = ReportData.msoList;
  let msoList = [];
  for (const value of GetmsoList) {
    msoList.push({ id: value.id, label: value.name });
  }

  async function selectedItem(val: string) {
    ReportData.currentMSO(val);
    dispatch({ type: "setMSOReport", payload: "" });
    dispatch({ type: "setReportLoader", payload: "loading" });
    dispatch({ type: "setSelectedMSO", payload: val });
    if (val !== "") {
      const resData = await LoadMSOReport({
        variables: {
          msoID: val,
        },
      });
      if (!resData.loading) {
        dispatch({
          type: "setMSOReport",
          payload: resData.data.individualReport,
        });

        dispatch({ type: "setReportLoader", payload: "show" });
      }
    } else {
      ReportData.currentModule("");
      dispatch({ type: "setReportLoader", payload: "hide" });
    }
  }

  return (
    <>
      <Col xl="12">
        <Card className="p-1">
          <CardHeader className="bg-transparent p-1 pb-2">
            <Row xl="12">
              <Col xl="12" className="text-center pb-2 ">
                <h5 className="ls-1 mb-0">MSO Individual Report</h5>
              </Col>

              <Col xl="12">
                <Dropdown
                  onChange={selectedItem}
                  options={msoList}
                  placeholder="Select MSO"
                />
              </Col>
            </Row>
          </CardHeader>
          <CardBody md="12" xl="12" className="p-0 minHeight">
            {state.reportLoader === "show" ? (
              <div className="pt-1">
                <fieldset
                  className="the-fieldset"
                  style={{ border: "2px solid green" }}
                >
                  <legend className="the-legend">MSO Individual Report</legend>

                  <div>
                    <ViewIndividualReport data={state.msoReport} />
                  </div>
                </fieldset>

                <fieldset
                  className="the-fieldset mt-1"
                  style={{ border: "2px solid green" }}
                >
                  <legend
                    className="the-legend"
                    style={{ background: "rgb(35 50 233)" }}
                  >
                    MSO Individual Subject Test Report
                  </legend>

                  {state.msoReport.subjectReport.map(
                    (data: any, index: number) => (
                      <Row md="12" xl="12" key={index} className="pl-2 pr-2">
                        <Col
                          md="12"
                          xl="12"
                          className="mt-2"
                          style={{ background: "#efe2d9" }}
                        >
                          {data.title}
                        </Col>
                        <Col md="12" className=" border subjectReport" xl="12">
                          <ViewModuleReport data={data.report} />
                        </Col>
                      </Row>
                    )
                  )}
                </fieldset>
              </div>
            ) : state.reportLoader === "loading" ? (
              <div className="loading">Loading...</div>
            ) : (
              <div className="dispMsg">Report Display Here</div>
            )}
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default IndividualReport;
